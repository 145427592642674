import React from 'react';
import './location.scss';

const Location = (props) => {
  let city = '';
  const onSubmitFields = () => {

    const prevPath = props.location.pathname
    props.history.push({ pathname: '/search_by_status', state: { city: city, prevPath: [prevPath] } });

  };
  const btnClick = (event) => {
    if (event) event.preventDefault();
    city = event.currentTarget.value;
    onSubmitFields();
  };

  return (
    <div>
      <div className="search-header"><a href="https://counselconnect.ca/"><img className="lawyer-search-logo" src={require('./images/cc-search-logo.svg')} alt="counsel-connect-log" /></a></div>
      <div className="Location">
       

        <img className="location-search-header-icon" src={require('./images/location.svg')} alt="" />


        <h1 className="location-search-title">Where are you located?</h1>
        <p className="location-search-subtitle">Select the city that best for you.</p>
        <div className="law-field-options">
          <button type="submit" className="status-search-lawyer-button" onClick={btnClick} value="Mississauga">Mississauga</button>
          <button type="submit" className="status-search-lawyer-button" onClick={btnClick} value="Toronto">Toronto</button>
        </div>
        <span className="location-search-subtitle-msg">Now serving Toronto!</span>
        <div className="location-page-num">1 of 5</div>
      </div>
    </div>

  );
};

export default Location;
