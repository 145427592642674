import React, { useEffect, useState, useRef, useCallback } from 'react';
// import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
// import PropTypes from 'prop-types';

import TextField from '../../reusable/text_field/TextField.jsx';
import TextAreaField from '../../reusable/text_area_field/TextAreaField';
import SelectList from '../../reusable/select_list/SelectList';
// import { createProfile } from '../../../actions/profileActions';
import http from '../../../utils/http';

import SubNavbar from '../../reusable/sub_navbar/SubNavbar';
import useForm from '../../../hooks/useForm';
// import iconProfile from '../../layout/navbar/images/profile-icon.svg';
import spinner from '../create-profile/spinner_2.gif';

import './EditProfile.scss';
import environment from '../../../utils/environment';
import { withAlert } from "react-alert";
import * as Icon from 'react-feather';


// TODO: errors
// TODO: pull out image uploading to action?


// const occupationTypeOptions = [
//   { label: '* Select', value: 0 },
//   { label: 'Sole Practitioner', value: 'Sole Practitioner' },
//   { label: 'Law Firm', value: 'Law Firm' },
// ];


// Select status options for professional status
// const statusOptions = [
//   { label: '* Select Status', value: 0 },
//   { label: 'Lawyer', value: 'Lawyer' },
//   { label: 'Paralegal', value: 'Paralegal' },
// ];

// Select field options for field of work
// const paralegalCategories = [
//   { label: '* Select Category', value: 0 },
//   { label: 'Traffic Tickets', value: 'Traffic Tickets' },
//   { label: 'Minor Offences', value: 'Minor Offences' },
//   { label: 'Landlord or Tenant', value: 'Landlord or Tenant' },
//   { label: 'Employment Law', value: 'Employment Law' },
//   { label: 'Small Claims', value: 'Small Claims' },
//   { label: 'Immigration Law', value: 'Immigration Law' },
// ];

// const lawyerCategories = [
//   { label: '* Select Category', value: 0 },
//   { label: 'Criminal Law', value: 'Criminal Law' },
//   { label: 'Commercial Law', value: 'Commercial Law' },
//   { label: 'Civil Law', value: 'Civil Law' },
//   { label: 'Employment Law', value: 'Employment Law' },
//   { label: 'Family Law', value: 'Family Law' },
//   { label: 'Immigration Law', value: 'Immigration Law' },
//   { label: 'Real Estate', value: 'Real Estate' },
// ];

// Select years of experience options for professional status
const citiesOptions = [
  { label: '* Select City', value: 0 },
  { label: 'Toronto', value: 'Toronto' },
  { label: 'Mississauga', value: 'Mississauga' },
  // { label: 'Milton', value: 'Milton' },
  // { label: 'Hamilton', value: 'Hamilton' },
];


const genderOptions = [
  { label: '* Select Gender', value: 0 },
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
];

const EditProfile = (props) => {

  
 

  // const [lawFirmInfo, setFirmInfo] = useState([]);

  const onSubmit = async(inputs) => {
 

    


    const profileData = {
      // handle: inputs.handle,

      status: inputs.status,
      field: inputs.field,
      gender: inputs.gender,

      languages: inputs.languages,
      
      website: inputs.website,
      linkedin: inputs.linkedin,
      bio: inputs.bio,

      city: inputs.city,
      address: inputs.address,
      postalcode: inputs.postalcode,
      phonenumber: inputs.phonenumber,

      pictureURL: picUrl ? picUrl : inputs.pictureURL
    };

    

    if(!profileData.languages) {
      props.alert.error("\"LANGUAGE\" IS REQUIRED");
      return;
    }
  
    try {
      
      await http.post(`${environment.resolveApi().rest}/profile/update`, profileData);
      setPageLoading(true);
      setPageLoading(false);
      props.history.push('/');
      props.alert.success("Profile Updated!");
    
    } catch (error) {
     
      if(error.gender) {
        props.alert.error(error.gender);
      }
      if(error.city) {
        props.alert.error(error.city);
      }
      if(error.phonenumber) {
        props.alert.error(error.phonenumber);
      }
      if(error.postalcode) {
        props.alert.error(error.postalcode);
      }
      if(error.address) {
        props.alert.error(error.address);
      }
      if(error.pictureURL) {
        props.alert.error("\"PROFILE PICTURE\" IS REQUIRED");
      }
      
    } 
  };

  const { inputs, setInputs, handleInputChange, handleSubmit } = useForm(onSubmit);

  const [isEdit, setIsEdit] = useState(false);



  useEffect(() => {
    const getProfileInfo = async () => {
      try {
        const response = await http.get(`${environment.resolveApi().rest}/profile/info`);
        // console.log(response.data.profile)
        setInputs(response.data.profile);
      } catch (error) {
        console.log(error);
      }
    };
    getProfileInfo();
  }, [setInputs]);

  const [picUrl, setPictureUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const uploadRef = useRef(null);

  const onImageClick = useCallback(() => {
    uploadRef.current.click();
  }, [uploadRef]);

  const onFileUpload = async (e) => {
    e.persist();

    const file = e.target.files[0];

    //  If the file size (calculated in byte form) is than 1MB
    if ( file.size > 1.0e+06 ) {
      return props.alert.error("IMAGE SIZE MUST BE LESS THAN 1MB");
    }
    if ( file.type !== "image/png" && file.type !== "image/jpeg" ) {
      return props.alert.error("IMAGE TYPE MUST BE PNG OR JPG");
    }

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append('file', file);

      const response = await http.uploadImage(`${environment.resolveApi().rest}/profile/upload`, formData);
    
      setLoading(false);

      setPictureUrl(response.data.pictureURL);

    } catch (error) {
      console.error(error);
    }
  };

  const [webEdit, setWebEdit] = useState(false);
  const [linkedinEdit, setLinkedinEdit] = useState(false);
  const [bioEdit, setBioEdit] = useState(false);



  if (!isEdit) {
    return (
      (
        <div className="EditProfile">
          <SubNavbar title="Edit Profile" config="profile" />
          <div className="profile-info-content-wrapper">
            <div className="profile-info-container">
              <div className="profile-info-content-container">
                <div className="profile-view-image-container">
                <img className="profile-view-image" src={inputs.pictureURL} alt="" />
                  <div className="edit-profile-image-text">
                    <h2 className="edit-profile-image-title">{`${inputs.first_name} ${inputs.last_name}`}</h2>
                  </div>
                </div>
                <div className="profile-info-tag-container">
                  <div className="profile-info-tag-content-container">
                    <h6>General</h6>
                    <hr />
                    <br />
                    <br />
                    <div className="profile-info-tag-heading-container">
                     
                     
                      <div>
                        <strong>Status</strong>
                        <h5>{inputs.status}</h5>
                      </div>
                      {/* <div>
                        <strong>Services</strong>
                        <h5 style={{  display: "flex", flexFlow: "column", width: "80px" }}>{inputs.field}</h5>
                      </div> */}
                      <div>
                        <strong>City</strong>
                        <h5>{inputs.city}</h5>
                      </div>
                      <div>
                        <strong>Postal Code</strong>
                        <h5>{inputs.postalcode}</h5>
                      </div>
                      <div>
                        <strong>Address</strong>
                        <h5>{inputs.address}</h5>
                      </div>
                      <div>
                        <strong>Phone Number</strong>
                        <h5>{inputs.phonenumber}</h5>
                      </div>
                    </div>
                  </div>

                  <div className="profile-info-tag-content-container">
                    <h6>About Me</h6>
                    <hr />
                    <br />
                    <br />
                    <div className="profile-info-tag-heading-container">
                      <div>
                        <strong>Gender</strong>
                        <h5>{inputs.gender}</h5>
                      </div>
                      <div>
                        <strong>Languages</strong>
                        <h5>{inputs.languages}</h5>
                      </div>
                      <div>
                        <strong>Website</strong>
                        <h5>{inputs.website}</h5>
                      </div>
                      <div>
                        <strong>Linkedin</strong>
                        <h5>{inputs.linkedin}</h5>
                      </div>
                     
                      <div>
                        <strong>Bio</strong>
                        <h5>{inputs.bio}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-info-edit-button-container">
                <button className="profile-info-edit-button" type="submit" onClick={() => setIsEdit(true)}>EDIT</button>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }

  return (
    <div className="EditProfile">
      <SubNavbar title="Edit Profile" config="profile" />


   {pageLoading ?  
      <div className="edit-profile-spinner-container">
          <h2 className="spinner-title">Loading</h2>
          <img className="create-profile-loading-spinner" src={spinner} alt="Loading..." />
      </div>

   :

    

      <div className="profile-edit-content-wrapper">

      {loading ?

          <div className="edit-profile-spinner-container">
              <h2 className="spinner-title">Setting Profile Image</h2>
              <img className="create-profile-loading-spinner" src={spinner} alt="Loading..." />
          </div>

          :
          <form onSubmit={handleSubmit} className="edit-profile-form-container">
          <div>
      
            <div className="edit-profile-c2-input-field-container">

            <span className="edit-profile-c3-title">General Info</span>
            <hr style={{width: '100%'}}></hr>
          
         
      

              <div className="edit-profile-c2-input-group-2">
                
            
              <div className="edit-profile-profile-pic-w">
                  <img className="new-lawfirm-uploaded-pic"  src={ picUrl ? picUrl : inputs.pictureURL } alt="" onClick={onImageClick}/>
                  <input type="file" onChange={onFileUpload} ref={uploadRef} />
              </div> 
              
              <div className="edit-profile-c2-input-group-2-text-fields-wp">
               
               <div className="edit-profile-c2-input-group-2-text-fields-wp-in">
                <TextField
                    title="Law title"
                    className="lw-portal-text-input"
                    name="status"
                    value={inputs.status}
                    onChange={handleInputChange}
                    info="Contact us to modify"
                    disableInput
                  />

                <TextField
                  title="Law Services"
                  className="lw-portal-text-input"
                  name="field"
                  value={inputs.field}
                  onChange={handleInputChange}
                  info="Contact us to modify"
                  disableInput
                />
         
                <SelectList
                   className="law-portal-select-list-input"
                   title="City"
                   placeholder=""
                   name="city"
                   value={inputs.city}
                   onChange={handleInputChange}
                   options={citiesOptions}
                   // error={errors.city}
                   info="Contact us to modify"
                   disableSelect
                 />

              </div>
              <div className="edit-profile-c2-input-group-2-text-fields-wp-in">
                <TextField
                  className="lw-portal-text-input"
                  title="Address"
                  placeholder=""
                  name="address"
                  value={inputs.address}
                  onChange={handleInputChange}
                  // error={errors.address}
                  info="Work address."
                  lawPortal
                />

                <TextField
                  className="lw-portal-text-input"
                  title="Postal Code"
                  placeholder=""
                  name="postalcode"
                  value={inputs.postalcode}
                  onChange={handleInputChange}
                  // error={errors.postalcode}
                  info="Postal Code."
                  lawPortal
                />


              <TextField
                  className="lw-portal-text-input"
                  title="Phone Number"
                  placeholder=""
                  name="phonenumber"
                  value={inputs.phonenumber}
                  onChange={handleInputChange}
                  // error={errors.phonenumber}
                  info="Cell number."
                  lawPortal
                />
                </div>
            </div>
                  
            </div>

          </div>

          

              <div className="edit-profile-c4-input-group">
                
              <span className="edit-profile-c3-title">About Me</span>
              <hr style={{width: '100%'}}></hr>
            
              
                
              </div>

          

              

              <div className="">
                
                <SelectList
                    className="law-portal-select-list-input"
                    title="Gender"
                    placeholder="*Male or Female"
                    name="gender"
                    value={inputs.gender}
                    onChange={handleInputChange}
                    options={genderOptions}
                    // error={errors.gender}
                    info="Help clients know."
                    lawPortal
                  />

                <TextField
                  className="lw-portal-text-input"
                  title="Languages"
                  placeholder=""
                  name="languages"
                  value={inputs.languages}
                  onChange={handleInputChange}
                  // error={errors.languages}
                  info="List language(s) spoken."
                  lawPortal
                />
             
             
           
             
             
         
              </div>

              <div className="edit-profile-container-3 create-profile-container-3">
              <span className="edit-profile-c3-title">Social</span>
              <hr style={{ width: '100%' }}></hr>

              <div className="edit-profile-c2-input-field-container create-profile-input-container-3">

              { webEdit === false ?
                <div className="create-profile-icon-wrap" onClick={()=>{setWebEdit(true)}}>
                  { inputs.website === null ?
                  <Icon.PlusSquare size={30} color={"#007AFF"}/>
                  :
                  <Icon.Edit size={30} color={"#007AFF"}/>
                  }
                  <span className="create-profile-icon-label">Personal Website </span> 
                </div>
                :
                <div className="edit-profile-c3-input-group create-profile-social-input-wrap-1">
                  <TextField
                    title="Website"
                    placeholder=""
                    name="website"
                    value={inputs.website}
                    onChange={handleInputChange}
                    // error={errors.website}
                    info="MUST include http://"
                    lawPortal
                  />
                  <Icon.XCircle className="create-profile-social-x-icon" size={30} color={"#D0021B"} onClick={()=>{  setWebEdit(false); }}/>
                </div>
              }
              { linkedinEdit === false ?
                <div className="create-profile-icon-wrap" onClick={()=>{setLinkedinEdit(true)}}>
                  { inputs.linkedin === null ?
                  <Icon.PlusSquare size={30} color={"#007AFF"}/>
                  :
                  <Icon.Edit size={30} color={"#007AFF"}/>
                  }
                  <span className="create-profile-icon-label">Linkedin Profile</span> 
                </div>
                :
                <div className="edit-profile-c3-input-group create-profile-social-input-wrap-2">
                  <TextField
                    title="Linkedin"
                    placeholder=""
                    name="linkedin"
                    value={inputs.linkedin}
                    onChange={handleInputChange}
                    // error={errors.linkedin}
                    info="MUST include http://"
                    lawPortal
                  />
                  <Icon.XCircle className="create-profile-social-x-icon" size={30} color={"#D0021B"} onClick={()=>{setLinkedinEdit(false)}}/>
                </div>
              }
              { bioEdit === false ?
                <div className="create-profile-icon-wrap" onClick={()=>{setBioEdit(true)}}>
                   { inputs.bio === null ?
                  <Icon.PlusSquare size={30} color={"#007AFF"}/>
                  :
                  <Icon.Edit size={30} color={"#007AFF"}/>
                  }
                  <span className="create-profile-icon-label">Personal Bio</span> 
                </div>
                :
                <div className="edit-profile-c3-input-group create-profile-social-input-wrap-3">
                  <TextAreaField
                    className="create-profile-text-area-field"
                    title="Bio"
                    placeholder=""
                    name="bio"
                    value={inputs.bio}
                    onChange={handleInputChange}
                    // error={errors.bio}
                    info="Bio for your profile."
                  />
                  <Icon.XCircle className="create-profile-social-x-icon" size={30} color={"#D0021B"} onClick={()=>{setBioEdit(false)}}/>
                </div>
              }



               
                
          </div>

        </div>
            
          </div>

          <div className="edit-profile-update-button-container">
            <button className="edit-profile-exit-button" type="submit" onClick={() => setIsEdit(false)}>EXIT</button>
            <button type="submit" className="edit-profile-update-button">UPDATE</button>
          </div>
        </form>
        }
      </div>
    }
    </div>
  );
};


export default withAlert()(EditProfile);
