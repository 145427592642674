import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { getCurrentProfile } from '../../../actions/profileActions';
import http from '../../../utils/http';
import TextAreaField from '../../reusable/text_area_field/TextAreaField';
import SelectList from '../../reusable/select_list/SelectList'
import useForm from '../../../hooks/useForm';
// import iconProfile from '../layout/navbar/images/profile-icon.svg';
import './Style/socialProfile.scss';
import { withAlert } from "react-alert";
import environment from '../../../utils/environment';
import RatingStar from './images/star.svg';

const mapStyles = {
  width: '100%',
  height: '350px',
  display: 'block !important',
  position: 'relative !important'
};


const paralegalCategories = [
  { label: '* Select Service', value: 0 },
  { label: 'Traffic Tickets', value: 'Traffic Tickets' },
  { label: 'Minor Offences', value: 'Minor Offences' },
  { label: 'Landlord or Tenant', value: 'Landlord or Tenant' },
  { label: 'Employment Law', value: 'Employment Law' },
  { label: 'Small Claims', value: 'Small Claims' },
  { label: 'Immigration Law', value: 'Immigration Law' },
];


const lawyerCategories = [
  { label: '* Select Service', value: 0 },
  { label: 'Criminal Law', value: 'Criminal Law' },
  { label: 'Commercial Law', value: 'Commercial Law' },
  { label: 'Civil Law', value: 'Civil Law' },
  { label: 'Employment Law', value: 'Employment Law' },
  { label: 'Family Law', value: 'Family Law' },
  { label: 'Immigration Law', value: 'Immigration Law' },
  { label: 'Real Estate', value: 'Real Estate' },
];



const SoleLawProfile = (props) => {

  const { handle: userHandle } = props.match.params;
  const { profile: profileState, google } = props;
  const { profile } = profileState;
  const contactHoursRef = useRef(null);

  const [profilePictureUrl, setProfilePictureUrl] = useState(null);
  const [bookingTimes, viewBookingTimes] = useState(true);
  const [contactForm, viewContactForm] = useState(false);
  const [successMessage, showSuccessMsg] = useState(false);
  // const [reviewsList, setReviewsList] = useState([{}]);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (inputs) => {
    try {
      const payload = { userHandle, firstName: inputs.firstName, lastName: inputs.lastName, email: inputs.email, phoneNumber: inputs.phoneNumber,  service: inputs.service, message: inputs.message };

      console.log(payload);

      if (!payload.firstName ||  !payload.lastName || !payload.email || !payload.phoneNumber || !payload.service || !payload.message) {
        props.alert.error("Missing Input!");
        return console.error('missing message');
      }
    
      await http.post(`${environment.resolveApi().rest}/mailing/appointment`, payload);
      props.alert.success("Sent Message!");
    } catch (error) {
      console.error('Sending Error!', error);
    }
    viewContactForm(false);
    showSuccessMsg(true);
  };

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();



  function highlightTodaysDate() {
    var d = new Date();
    var n = d.getDay();

    if(n === 0){
      n = 6;
    }
    else {
      n--;
    }

    for (let i = 0; i < 3; i++) {
      contactHoursRef.current.children[n].children[i].style.color = '#D0021B';
      contactHoursRef.current.children[n].children[i].style.fontWeight = 'bold';
    }
  }

  const { inputs, handleInputChange, handleSubmit } = useForm(onSubmit);

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const response = await http.get(`${environment.resolveApi().rest}/profile/${userHandle}/info`);
        setFirstName(response.data.firstName);
        setLastName(response.data.lastName);
        highlightTodaysDate();
      } catch (error) {
        console.log(error);
      }
    };
    setLoading(true);
    if (profile) {
      const { pictureURL } = profile;
      getUserInfo();
      setProfilePictureUrl(pictureURL);
    }
    setLoading(false);
  }, [profile, userHandle, contactHoursRef]);






  // early returns
  // if (loading) return <div>loading</div>;
  if (!profile) return <div>no profile</div>;

  const description = `${profile.status}`;




  return (
    <div className="SocialProfile">
      {!loading ?
        <div>
          <div className="social-profile-header-container">
            <img className="social-profile-view-image" src={profilePictureUrl} alt="" />
            <div className="social-profile-header-text-container">
              <h1 className="social-profile-name">{`${firstName} ${lastName}`}</h1>
              <h2 className="social-profile-law-status">{description}</h2>
              <div className="social-profile-website-links-container">
                
                { profile.website ?
                <a className="social-profile-website-link" href={profile.website} target="_blank">
                  <img width={"20px"} src={require('./images/globe.svg')} alt="" />
                  Website
                </a>
                :
                ''
                }
                { profile.linkedin ?
                <a className="social-profile-website-link" href={profile.linkedin} target="_blank">
                  <img src={require('./images/linkedin.svg')} alt="" />
                  Linkedin
                </a>
                :
                ''
                }
              </div>
              {/* <img style={{maxWidth: '300px', marginTop: '2.5em'}} src={lawFirmInfo.pictureURL}/> */}
            </div>

            <div>
              {/*  */}

              {bookingTimes
                && (
                  <div className="social-profile-contact-container">
                    <div className="social-profile-contact-title-container">
                      <img src={require('./images/availability.svg')} alt="" />
                      <strong>CONNECT WITH {profile.status.toUpperCase()}</strong>
                    </div>
                    {profile.availability ?
                      <div>
                        <strong className="social-profile-contact-hours-text">Hours</strong>
                        <div className="social-profile-contact-hours-container" ref={contactHoursRef}>
                          <div className="social-profile-contact-hours-text-container">
                            <span>Mon.</span>
                            <hr />
                            <span>{`${profile.availability.monday.from} - ${profile.availability.monday.to}`}</span>
                          </div>
                          <div className="social-profile-contact-hours-text-container">
                            <span>Tue.</span>
                            <hr />
                            <span>{`${profile.availability.tuesday.from} - ${profile.availability.tuesday.to}`}</span>
                          </div>
                          <div className="social-profile-contact-hours-text-container">
                            <span>Wed.</span>
                            <hr />
                            <span>{`${profile.availability.wednesday.from} - ${profile.availability.wednesday.to}`}</span>
                          </div>
                          <div className="social-profile-contact-hours-text-container">
                            <span>Thu.</span>
                            <hr />
                            <span>{`${profile.availability.thursday.from} - ${profile.availability.thursday.to}`}</span>
                          </div>
                          <div className="social-profile-contact-hours-text-container">
                            <span>Fri.</span>
                            <hr />
                            <span>{`${profile.availability.friday.from} - ${profile.availability.friday.to}`}</span>
                          </div>
                          <div className="social-profile-contact-hours-text-container">
                            <span>Sat.</span>
                            <hr />
                            <span>{`${profile.availability.saturday.from} - ${profile.availability.saturday.to}`}</span>
                          </div>
                          <div className="social-profile-contact-hours-text-container">
                            <span>Sun.</span>
                            <hr />
                            <span>{`${profile.availability.sunday.from} - ${profile.availability.sunday.to}`}</span>
                          </div>
                        </div>
                      </div>
                      :
                      <p>
                        Availability timing not currently set
          </p>
                    }
                    <button type="submit" onClick={() => { viewBookingTimes(false); viewContactForm(true); }} className="social-profile-contact-button">CONNECT</button>

                  </div>

                )}


              {contactForm
                && (
                  <div className="social-profile-contact-msg-container">
                    <div className="social-profile-contact-title-container">
                      <img src={require('./images/availability.svg')} alt="" />
                      <strong>CONNECT WITH {profile.status.toUpperCase()}</strong>
                    </div>
                    {/* <strong className="social-profile-contact-hours-text">Hours</strong> */}
                    <form onSubmit={handleSubmit}>
                      <div className="social-profile-contact-hours-container" style={{ marginBottom: '45px' }}>
                        <div className="input-container">
                          <span className="lw-portal-text-input-title">First Name</span>
                          <input
                            className="lw-portal-text-input text-field-name"
                            placeholder=""
                            name="firstName"
                            type="text"
                            value={inputs.firstName}
                            onChange={handleInputChange}
                            autoComplete="off"
                          />
                        </div>

                        <div className="input-container">
                          <span className="lw-portal-text-input-title">Last Name</span>
                          <input
                            className="lw-portal-text-input text-field-name"
                            placeholder=""
                            name="lastName"
                            type="text"
                            value={inputs.lastName}
                            onChange={handleInputChange}
                            autoComplete="off"
                          />
                        </div>

                        <div className="input-container">
                            <span className="lw-portal-text-input-title">Phone</span>
                            <input
                              className="lw-portal-text-input text-field-time"
                              placeholder=""
                              type="text"
                              name="phoneNumber"
                              value={inputs.phoneNumber}
                              onChange={handleInputChange}
                              autoComplete="off"
                            />
                          </div>

                       
                        <div className="sp-booking-date-container">
                          <div className="input-container">
                            <span className="lw-portal-text-input-title">Email</span>
                            <input
                              className="lw-portal-text-input text-field-email"
                              placeholder=""
                              name="email"
                              type="email"
                              value={inputs.email}
                              onChange={handleInputChange}
                              autoComplete="off"
                            />
                          </div>
                          
                         

                          {/* <div className="input-container">
                            <span className="lw-portal-text-input-title">Date</span>
                            <input
                              className="lw-portal-text-input text-field-date"
                              placeholder=""
                              name="date"
                              type="date"
                              value={inputs.date}
                              onChange={handleInputChange}
                              autoComplete="off"
                            />
                          </div> */}

                          {/* <div className="input-container">
                            <span className="lw-portal-text-input-title">Service</span>
                            <input
                              className=" service-input"
                              placeholder=""
                              name="service"
                              type="text"
                              value={inputs.service}
                              onChange={handleInputChange}
                              autoComplete="off"
                            />
                          </div> */}

                            <SelectList
                              className="law-title-input law-search-select-input law-search-select-service-input"
                                title="Law Service"
                                name="service"
                                value={inputs.service}
                                onChange={handleInputChange}
                                options={ profile.status === "Lawyer" ? lawyerCategories : paralegalCategories }
                              //   error={errors.status}
                              //   info="Lawyer or Paralegal?"
                                lawPortal
                              />


                        </div>

                        <div>
                          <TextAreaField
                            className="modal-text-area-field text-field-msg"
                            title="Message"
                            placeholder=""
                            name="message"
                            type="text"
                            value={inputs.message}
                            onChange={handleInputChange}
                            autoComplete="off"
                          />
                        </div>

                      </div>
                      <button type="submit" className="social-profile-contact-button social-profile-contact-book-button">CONNECT</button>
                    </form>
                    <p className="consent-txt">By submitting your contact information, you agree to our Terms of Service and Privacy Policy and consent to having CounselConnect contact you by text message and email.</p>
                  </div>
                )}


              {successMessage
                && (
                  <div className="social-profile-contact-container social-profile-contact-success-container">
                    <div className="sp-contact-msg-container">
                      <img src={require('./images/success.svg')} alt="" />
                      <span className="sp-contact-success-msg-txt">Message Sent !</span>
                      <span className="sp-contact-info-msg-txt">We have forwarded your connection request to {firstName}, they shall be contacting you very soon.</span>
                    </div>
                  </div>
                )}

              {/*  */}
            </div>
          </div>


          <div className="social-profile-content-container">

            <div className="social-profile-content-1">

              <div className="social-profile-content-1-title-container">
                <span>What you need to know</span>
                <div>
                  <img src={require('./images/oval.svg')} alt="" />
                  <hr />
                </div>
              </div>

              <div className="social-profile-content-1-body">
                <div className="social-profile-content-1-body-subtitle">
                  <span>Languages spoken</span>
                  <img src={require('./images/languages.svg')} alt="" />
                </div>
                <div>
                  <p className="social-profile-sub-labels">{profile.languages}</p>
                </div>
              </div>

              <div className="social-profile-content-1-body">
                <div className="social-profile-content-1-body-subtitle">
                  <span className="social-profile-sub-labels">Areas of service</span>
                  <img src={require('./images/tool.svg')} alt="" />
                </div>
                <div style={{ lineHeight: '1.2' }}>
                  {profile.field.map((field,index) => {
                    return (
                      <p key={index} className="social-profile-sub-labels">{field}</p>
                    );
                  })}
                </div>
              </div>
            </div>
            {profile.lat && profile.lat ?
              <div className="social-profile-content-1-office">

                <div className="social-profile-content-1-title-container">
                  <span>Office</span>
                  <div>
                    <img src={require('./images/oval.svg')} alt="" />
                    <hr />
                  </div>

                  <span>{profile.address}</span>
                  <div className="address-sub-details">
                    <span>
                      {profile.city}
                      ,
              </span>
                    <span style={{ marginLeft: '10px' }}>
                      {' '}
                      ON
                {' '}
                      {profile.postalcode}
                    </span>
                  </div>
                </div>

                <Map
                  google={google}
                  zoom={14}
                  style={mapStyles}
                  containerStyle={{ display: 'block', position: 'relative' }}
                  initialCenter={{ lat: profile.lat, lng: profile.lng }}
                  disableDoubleClickZoom={true}
                  disableDefaultUI={true}
                  maxZoom={14}
                  minZoom={14}
                >
                  <Marker position={{ lat: profile.lat, lng: profile.lng }} />
                </Map>



                <div className="social-profile-content-1-body">
                  <div className="social-profile-content-1-body-subtitle" />
                  <div />
                </div>

              </div>
              :
              <div className="social-profile-content-1-office" style={{ height: "10%" }}>

                <div className="social-profile-content-1-title-container">
                  <span>Office</span>
                  <div>
                    <img src={require('./images/oval.svg')} alt="" />
                    <hr />
                  </div>

                  <span>{profile.address}</span>
                  <div className="address-sub-details">
                    <span>
                      {profile.city}
                      ,
     </span>
                    <span style={{ marginLeft: '10px' }}>
                      {' '}
                      ON
       {' '}
                      {profile.postalcode}
                    </span>
                  </div>
                </div>

              </div>
            }

            {profile.bio ?
              <div className="social-profile-content-1">

                <div className="social-profile-content-1-title-container">
                  <span className="social-profile-section-title">{`${firstName} ${lastName}`}</span>
                  <div>
                    <img src={require('./images/oval.svg')} alt="" />
                    <hr />
                  </div>
                </div>

                <div className="social-profile-content-1-body">
                  <div className="social-profile-content-bio-container">

                    {profilePictureUrl ?
                      <img className="social-profile-view-image" src={profilePictureUrl} alt="" />
                      :
                      <div></div>
                    }
                    <p>{profile.bio}</p>
                  </div>
                </div>

              </div>
              :

              <div></div>
            }


            {profile.experience.length > 0 ?
              <div className="social-profile-content-education">

                <div className="social-profile-content-education-title">
                  <span>Experience</span>
                  <div>
                    <img src={require('./images/oval.svg')} alt="" />
                    <hr />
                  </div>
                </div>

                <div className="social-profile-content-edu-wrapper">

                  {profile.experience.map((exp,index) => (
                    <div key={index} className="social-profile-content-education-body-subtitle">
                      <div className="social-profile-content-education-subtitle">
                        <img className="exp-img" src={require('./images/exp.svg')} alt="" />
                        <strong>EXPERIENCE</strong>
                      </div>
                      <div className="social-profile-content-education-body">
                        <div className="social-profile-content-education-body-item-1">
                          <span className="social-profile-content-edu-deg-type">{exp.title}</span>
                          <p className="social-profile-content-edu-deg-major">{exp.company}</p>
                        </div>
                        <div className="social-profile-content-education-body-item-2">
                          <span className="social-profile-content-edu-uni-name">{exp.location}</span>
                          <p className="social-profile-content-edu-deg-date">{exp.to ? `${exp.from.substring(0, 7)} - ${exp.to.substring(0, 6)}` : `${exp.from.substring(0, 7)} - Present`}</p>
                        </div>
                      </div>
                    </div>
                  ))
                  }
                </div>
              </div>
              :
              <div></div>
            }


            {profile.education.length > 0 ?
              <div className="social-profile-content-education">

                <div className="social-profile-content-education-title">
                  <span>Education</span>
                  <div>
                    <img src={require('./images/oval.svg')} alt="" />
                    <hr />
                  </div>
                </div>

                <div className="social-profile-content-edu-wrapper">

                  {profile.education.map(edu => (
                    <div className="social-profile-content-education-body-subtitle">
                      <div className="social-profile-content-education-subtitle">
                        <img src={require('./images/diploma.svg')} alt="" />
                        <strong>EDUCATION</strong>
                      </div>
                      <div className="social-profile-content-education-body">
                        <div className="social-profile-content-education-body-item-1">
                          <span className="social-profile-content-edu-deg-type">{edu.degree}</span>
                          <p className="social-profile-content-edu-deg-major">{edu.fieldofstudy}</p>
                        </div>
                        <div className="social-profile-content-education-body-item-2">
                          <span className="social-profile-content-edu-uni-name">{edu.school}</span>
                          <p className="social-profile-content-edu-deg-date">{edu.to ? `${edu.from.substring(0, 7)} - ${edu.to.substring(0, 6)}` : `${edu.from.substring(0, 7)} - Present`}</p>
                        </div>
                      </div>
                    </div>
                  ))
                  }
                </div>
              </div>
              :
              <div></div>
            }


            

          </div>

          {
                profile.reviews && profile.reviews.length > 0 ?
                  <div className="reviews-container">
                    <div className="social-profile-content-1-title-container">
                      <span className="social-profile-section-title"><span style={{ color: "#D0021B" }}>{profile.reviews.length}</span> Detailed Reviews</span>
                      <div>
                        <img src={require('./images/oval.svg')} alt="oval" />
                        <hr />
                      </div>
                    </div>
                    {profile.reviews.map(rev => (
                      <div className="review">
                        <div className="rating">
                          <div className="rating-container">
                            {
                              rev.rating ?
                                [...Array(parseInt(rev.rating))].map(i =>
                                  <img src={RatingStar} alt="rating" />
                                ) : ''
                            }
                          </div>

                          <p>Reviewed on</p>
                          <p><b>{
                            rev.date.split("T")[0]

                          }</b></p>
                        </div>
                        <div className="desc">{rev.review}</div>
                      </div>
                    ))}
                  </div>

                  : ''
              }



        </div>
        :
        <p>Loading ...</p>
      }
    </div>
  );
};


SoleLawProfile.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
  profile: state.profile,
  errors: state.errors,
});

export default connect(mapStateToProps, { getCurrentProfile })(withRouter(GoogleApiWrapper({ apiKey: ('AIzaSyBydKebS6oGDjm95HKdQi2YzP6wLA4iYaI') })(withAlert()(SoleLawProfile))));
