import React, { useState, useRef, useCallback } from 'react';
import TextField from '../../reusable/text_field/TextField.jsx';
import TextAreaField from '../../reusable/text_area_field/TextAreaField';
import SelectList from '../../reusable/select_list/SelectList';
import defaultPic from './employee_default_pic.svg';
import spinner from './spinner_2.gif';

// import { createProfile, getCurrentProfile } from '../../../actions/profileActions';
import http from '../../../utils/http';
import useForm from '../../../hooks/useForm';
import { withAlert } from "react-alert";
import environment from '../../../utils/environment';

import * as Icon from 'react-feather';

import '../profile_info/EditProfile.scss';
import './createProfile.scss';

// Select status options for professional status
const statusOptions = [
  { label: '* Select Law Title', value: 0 },
  { label: 'Lawyer', value: 'Lawyer' },
  { label: 'Paralegal', value: 'Paralegal' },
];

// Select field options for field of work
const paralegalCategories = [
  { label: 'Traffic Tickets', value: 'Traffic Tickets' },
  { label: 'Minor Offences', value: 'Minor Offences' },
  { label: 'Landlord or Tenant', value: 'Landlord or Tenant' },
  { label: 'Employment Law', value: 'Employment Law' },
  { label: 'Small Claims', value: 'Small Claims' },
  { label: 'Immigration Law', value: 'Immigration Law' },
];

const lawyerCategories = [
  { label: 'Criminal Law', value: 'Criminal Law' },
  { label: 'Commercial Law', value: 'Commercial Law' },
  { label: 'Civil Law', value: 'Civil Law' },
  { label: 'Employment Law', value: 'Employment Law' },
  { label: 'Family Law', value: 'Family Law' },
  { label: 'Immigration Law', value: 'Immigration Law' },
  { label: 'Real Estate', value: 'Real Estate' },
];

// Select years of experience options for professional status
const citiesOptions = [
  { label: '* Select City', value: 0 },
  { label: 'Toronto', value: 'Toronto' },
  { label: 'Mississauga', value: 'Mississauga' },
  // { label: 'Milton', value: 'Milton' },
  // { label: 'Hamilton', value: 'Hamilton' },
];


const genderOptions = [
  { label: '* Select Gender', value: 0 },
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
];

const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const timeOptions = [
  { label: '* Select Time', value: 0 },
  { label: 'Closed', value: 'Closed' },
  { label: '6:00 AM', value: '6:00 AM' },
  { label: '7:00 AM', value: '7:00 AM' },
  { label: '8:00 AM', value: '8:00 AM' },
  { label: '9:00 AM', value: '9:00 AM' },
  { label: '10:00 AM', value: '10:00 AM' },
  { label: '11:00 AM', value: '11:00 AM' },
  { label: '12:00 PM', value: '12:00 PM' },
  { label: '1:00 PM', value: '1:00 PM' },
  { label: '2:00 PM', value: '2:00 PM' },
  { label: '3:00 PM', value: '3:00 PM' },
  { label: '4:00 PM', value: '4:00 PM' },
  { label: '5:00 PM', value: '5:00 PM' },
  { label: '6:00 PM', value: '6:00 PM' },
  { label: '7:00 PM', value: '7:00 PM' },
  { label: '8:00 PM', value: '8:00 PM' },
  { label: '9:00 PM', value: '9:00 PM' },
  { label: '10:00 PM', value: '10:00 PM' },
];

const CreateProfile = (props) => {


  const onSubmit = async (inputs) => {

    const availabilityData = {
      ...days.reduce((acc, day) => ({
        ...acc,
        [day]: {
          from: inputs[`${day}from`],
          to: inputs[`${day}to`],
        },
      }), {}),
    };


    if (!availabilityData.monday.from || !availabilityData.monday.to ||
      !availabilityData.tuesday.from || !availabilityData.tuesday.to ||
      !availabilityData.wednesday.from || !availabilityData.wednesday.to ||
      !availabilityData.thursday.from || !availabilityData.thursday.to ||
      !availabilityData.friday.from || !availabilityData.friday.to ||
      !availabilityData.saturday.from || !availabilityData.saturday.to ||
      !availabilityData.sunday.from || !availabilityData.sunday.to) {
      props.alert.error("Missing Availability Hours");
      return;
    }

    const profileData = {
      handle: inputs.handle,

      first_name: '',
      last_name: '',

      status: inputs.status,
      field: lawFields,

      gender: inputs.gender,
      languages: inputs.languages,
      website: inputs.website,
      linkedin: inputs.linkedin,
      bio: inputs.bio,

      city: inputs.city,
      postalcode: inputs.postalcode,
      address: inputs.address,
      phonenumber: inputs.phonenumber,

      // pictureURL: picUrl,
      pictureURL: picUrl,


      availability: availabilityData,
    };



    try {
      if (!profileData.languages) {
        props.alert.error("\"LANGUAGE\" IS REQUIRED");
        return;
      }
      await http.post(`${environment.resolveApi().rest}/profile/create`, profileData);
      props.alert.success("Profile Created!");
      props.history.push('/');
      window.location.reload();
    } catch (error) {
      if (error.status) {
        props.alert.error("\"Law Title\" IS REQUIRED");
      }
      if (error.field) {
        props.alert.error("\"Law Services\" IS REQUIRED");
      }
      if (error.gender) {
        props.alert.error(error.gender);
      }
      if (error.city) {
        props.alert.error(error.city);
      }
      if (error.phonenumber) {
        props.alert.error(error.phonenumber);
      }
      
      if (error.postalcode) {
        props.alert.error(error.postalcode);
      }
      if (error.address) {
        props.alert.error(error.address);
      }
      if (error.pictureURL) {
        props.alert.error("\"PROFILE PICTURE\" IS REQUIRED");
      }

    }
  }


  const { inputs, handleInputChange, handleSubmit } = useForm(onSubmit);

  const [lawFields, setLawFields] = useState([]);

  const handleChange = selectedOption => {
    setLawFields(selectedOption);
    groupFeilds(selectedOption);
  };

  const groupFeilds = selectedOption => {

    let arr = [];

    if (selectedOption != null) {
      for (let i = 0; i < selectedOption.length; i++) {
        arr.push(selectedOption[i].value);
      }
    }
    setLawFields(arr);
  };


  const [picUrl, setPictureUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const uploadRef = useRef(null);
  const [webEdit, setWebEdit] = useState(false);
  const [linkedinEdit, setLinkedinEdit] = useState(false);
  const [bioEdit, setBioEdit] = useState(false);


  const onImageClick = useCallback(() => {
    uploadRef.current.click();
  }, [uploadRef]);

  const onFileUpload = async (e) => {
    e.persist();

    const file = e.target.files[0];

    //  If the file size (calculated in byte form) is than 1MB
    if (file.size > 1.0e+06) {
      return props.alert.error("IMAGE SIZE MUST BE LESS THAN 1MB");
    }
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      return props.alert.error("IMAGE TYPE MUST BE PNG OR JPG");
    }

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append('file', file);

      const response = await http.uploadImage(`${environment.resolveApi().rest}/profile/upload`, formData);

      setLoading(false);

      setPictureUrl(response.data.pictureURL);

    } catch (error) {
      console.error(error);
    }
  };



  return (
    <div className="CreateProfile EditProfile">

      {loading ?

        <div className="edit-profile-spinner-container">
          <h2 className="spinner-title">Setting Profile Image</h2>
          <img className="create-profile-loading-spinner" src={spinner} alt="Loading..." />
        </div>

        :

        <div className="create-profile-form-container">
          <form onSubmit={handleSubmit}>

            <div className="edit-profile-container-2">
              <span className="edit-profile-c2-title">General</span>
              <hr style={{ width: '100%', marginBottom: '40px' }}></hr>
              <div className="create-profile-c2-input-field-container ">

                <div className="create-profile-profile-pic-w">
                  <img className="new-lawfirm-uploaded-pic" src={picUrl ? picUrl : defaultPic} alt="" onClick={onImageClick} />
                  <input type="file" onChange={onFileUpload} ref={uploadRef} />
                </div>

                <div className="create-profile-c2-input-group-2">
                  <SelectList
                    title="Law Title"
                    name="status"
                    value={inputs.status}
                    onChange={handleInputChange}
                    options={statusOptions}
                    // error={errors.status}
                    info="Lawyer or Paralegal?"
                    className="law-portal-select-list-input"
                    lawPortal
                  />
                </div>

                <div className="create-profile-c2-input-3">
                  <SelectList
                    className="law-field-input-small"
                    title="Law Services"
                    name="lawField"
                    value={inputs.field}
                    onChange={handleChange}
                    options={inputs.status === 'Paralegal' ? paralegalCategories : lawyerCategories}
                    multiSelect
                  />
                </div>

               

              </div>
          <div className="create-profile-c3-r2-input-group">
              <div className="edit-profile-c3-input-group">
                  <SelectList
                    title="Gender"
                    placeholder="*Male or Female"
                    name="gender"
                    value={inputs.gender}
                    onChange={handleInputChange}
                    options={genderOptions}
                    // error={errors.gender}
                    info=""
                    className="law-portal-select-list-input"
                    lawPortal
                  />
                </div>
                <div className="edit-profile-c3-input-group">
                  <TextField
                    title="Languages"
                    placeholder=""
                    name="languages"
                    value={inputs.languages}
                    onChange={handleInputChange}
                    // error={errors.languages}
                    info="List language(s) spoken."
                    lawPortal
                  />
                </div>
                </div>
            </div>


            {/* <div className="edit-profile-container-3 create-profile-container-3">
              <span className="edit-profile-c3-title">About</span>
              <hr style={{ width: '100%' }}></hr>

              <div className="edit-profile-c2-input-field-container">
                
               
              </div>

            </div> */}


            <div className="edit-profile-container-3 create-profile-container-3">
              <span className="edit-profile-c3-title">Social</span>
              <hr style={{ width: '100%' }}></hr>

              <div className="edit-profile-c2-input-field-container create-profile-input-container-3">

              { webEdit === false ?
                <div className="create-profile-icon-wrap" onClick={()=>{setWebEdit(true)}}>
                  <Icon.PlusSquare size={30} color={"#007AFF"}/>
                  <span className="create-profile-icon-label">Personal Website </span> 
                </div>
                :
                <div className="edit-profile-c3-input-group create-profile-social-input-wrap-1">
                  <TextField
                    title="Website"
                    placeholder=""
                    name="website"
                    value={inputs.website}
                    onChange={handleInputChange}
                    // error={errors.website}
                    info="MUST include http://"
                    lawPortal
                  />
                  <Icon.XCircle className="create-profile-social-x-icon" size={30} color={"#D0021B"} onClick={()=>{setWebEdit(false)}}/>
                </div>
              }
              { linkedinEdit === false ?
                <div className="create-profile-icon-wrap" onClick={()=>{setLinkedinEdit(true)}}>
                  <Icon.PlusSquare size={30} color={"#007AFF"}/>
                  <span className="create-profile-icon-label">Linkedin Profile</span> 
                </div>
                :
                <div className="edit-profile-c3-input-group create-profile-social-input-wrap-2">
                  <TextField
                    title="Linkedin"
                    placeholder=""
                    name="linkedin"
                    value={inputs.linkedin}
                    onChange={handleInputChange}
                    // error={errors.linkedin}
                    info="MUST include http://"
                    lawPortal
                  />
                  <Icon.XCircle className="create-profile-social-x-icon" size={30} color={"#D0021B"} onClick={()=>{setLinkedinEdit(false)}}/>
                </div>
              }
              { bioEdit === false ?
                <div className="create-profile-icon-wrap" onClick={()=>{setBioEdit(true)}}>
                  <Icon.PlusSquare size={30} color={"#007AFF"}/>
                  <span className="create-profile-icon-label">Personal Bio</span> 
                </div>
                :
                <div className="edit-profile-c3-input-group create-profile-social-input-wrap-3">
                  <TextAreaField
                    className="create-profile-text-area-field"
                    title="Bio"
                    placeholder=""
                    name="bio"
                    value={inputs.bio}
                    onChange={handleInputChange}
                    // error={errors.bio}
                    info="Bio for your profile."
                  />
                  <Icon.XCircle className="create-profile-social-x-icon" size={30} color={"#D0021B"} onClick={()=>{setBioEdit(false)}}/>
                </div>
              }



                {/* <div className="edit-profile-c3-input-group">
                  <TextField
                    title="Website"
                    placeholder=""
                    name="website"
                    value={inputs.website}
                    onChange={handleInputChange}
                    // error={errors.website}
                    info="Law firm website URL."
                    lawPortal
                  />
                </div>
                <div className="edit-profile-c3-input-group">
                  <TextField
                    title="Linkedin"
                    placeholder=""
                    name="linkedin"
                    value={inputs.linkedin}
                    onChange={handleInputChange}
                    // error={errors.linkedin}
                    info="Law firm linkedin profile URL."
                    lawPortal
                  />
                </div>

                <div className="edit-profile-c3-input-group">
                  <TextAreaField
                    className="create-profile-text-area-field"
                    title="Bio"
                    placeholder=""
                    name="bio"
                    value={inputs.bio}
                    onChange={handleInputChange}
                    // error={errors.bio}
                    info="Bio for your profile."
                  />
                </div> */}
                
          </div>

        </div>

            <div className="edit-profile-container-4">
              <span className="edit-profile-c4-title">Contact</span>
              <hr style={{ width: '100%' }}></hr>
              <div className="edit-profile-c4-input-field-container">
                <div className="edit-profile-c4-input-group">
                  <SelectList
                    title="City"
                    placeholder=""
                    name="city"
                    value={inputs.city}
                    onChange={handleInputChange}
                    options={citiesOptions}
                    // error={errors.city}
                    info="City that you live in."
                    className="law-portal-select-list-input"
                    lawPortal
                  />
                </div>
                <div className="edit-profile-c4-input-group">
                  <TextField
                    title="Postal Code"
                    placeholder=""
                    name="postalcode"
                    value={inputs.postalcode}
                    onChange={handleInputChange}
                    // error={errors.postalcode}
                    info="Postal Code."
                    lawPortal
                  />
                </div>
                <div className="edit-profile-c4-input-group">
                  <TextField
                    title="Address"
                    placeholder=""
                    name="address"
                    value={inputs.address}
                    onChange={handleInputChange}
                    // error={errors.address}
                    info="Work Address."
                    lawPortal
                  />
                </div>
                <div className="edit-profile-c4-input-group">
                  <TextField
                    title="Phone Number"
                    placeholder=""
                    name="phonenumber"
                    value={inputs.phonenumber}
                    onChange={handleInputChange}
                    // error={errors.phonenumber}
                    info="Personal or law firm number."
                    lawPortal
                  />
                </div>

              </div>

            </div>

            <div className="edit-profile-container-4">
              <span className="edit-profile-c4-title">Availability</span>
              <hr style={{ width: '100%' }}></hr>

              <div className="edit-avail-form-container-create-profile">
                {
                  days.map(day => (
                    <div key={day} className="edit-avail-input-container-1-create-profile">
                      <h3 className="edit-avail-day-text">{day.charAt(0).toUpperCase() + day.slice(1)}</h3>
                      <div className="edit-avail-select-container-create-profile">
                        <div className="edit-avail-select-input-1">
                          <SelectList
                            className="law-portal-select-list-input law-portal-select-list-input-create-profile"
                            title="From"
                            placeholder=""
                            name={`${day}from`}
                            value={inputs[`${day}from`]}
                            onChange={handleInputChange}
                            options={timeOptions}
                            info=""
                            lawPortal
                          />
                        </div>
                        <div className="edit-avail-select-input-2">
                          <SelectList
                            className="law-portal-select-list-input law-portal-select-list-input-create-profile"
                            title="To"
                            placeholder=""
                            name={`${day}to`}
                            value={inputs[`${day}to`]}
                            onChange={handleInputChange}
                            options={timeOptions}
                            info=""
                            lawPortal
                          />
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
            <div className="edit-profile-update-button-container">
              <button type="submit" className="edit-profile-update-button">CREATE PROFILE</button>
            </div>
          </form>
        </div>
      }
    </div>
  );
};

export default withAlert()(CreateProfile);
