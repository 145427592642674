import React, {  useState } from 'react';
import http from '../../utils/http';
import useForm from '../../hooks/useForm';
import './style/register.scss';
import logo from './images/logo.svg';
import mailBox from './mail-box.svg';
import TextField from '../reusable/text_field/TextField.jsx';
import { Link } from 'react-router-dom';
import { withAlert } from "react-alert";
import environment from '../../utils/environment';

const PasswordResetRequest = (props) => {
  const [isEdit, setIsEdit] = useState(false);


    const onSubmit = async (inputs) => {
        if (!inputs.email || inputs.email.length === 0) {
          props.alert.error("Missing Email!");
          return console.error('missing message');
        }
        try {
          const payload = { userEmail: inputs.email };
          await http.post(`${environment.resolveApi().rest}/mailing/password_reset/token`, payload);
          props.alert.success("Request Sent!");
          // props.history.push('/');
          setIsEdit(true);
        } catch (error) {
          console.error('Sending Error!', error);
          if(error.message) {
            props.alert.error(error.message);
          }
        }
      };

    const { inputs, handleInputChange, handleSubmit } = useForm(onSubmit);

  return (
    <div className="PasswordResetRequest">
      <div className="signup-header">
         <img className="side-navbar-home-icon" src={logo} alt="" />
    {!isEdit ?
      <div>
          <h1 className="signup-title">Reset Password</h1>
          <p>Enter in you're email.</p>
        
          <form className="password-reset-request-form" onSubmit={handleSubmit}>
              <TextField
                className="login-portal-input"
                title="Email"
                placeholder=""
                name="email"
                type="email"
                value={inputs.email}
                onChange={handleInputChange}
                // error={errors.login}
              />
              <button type="submit" style={{marginTop: '2em'}} className="signup-button password-reset-button">Send Reset Email</button>
          </form>

          <Link to="/" className="back-to-login-link">← Back to Login</Link>
        </div>
        :
        <div className="check-password-reset-email-container">
          <h1 className="signup-title">Please check you're email</h1>
          <p>We have sent you a password reset link.</p>
          <img className="mail-box-icon" src={mailBox} alt="" />
            <a href='https://counselconnect.ca/'>
            <button type="submit" style={{marginTop: '2em'}} className="signup-button password-reset-button">RETURN HOME</button>
            </a>
        </div>
      }
        </div>
      </div>
  );
};


export default withAlert()(PasswordResetRequest);
