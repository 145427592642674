import React, { useState, useEffect } from 'react';
import http from '../../../utils/http';
import useForm from '../../../hooks/useForm';
import ProfileItem from '../law_profile_cards/ProfileItem';
import TextField from '../../reusable/text_field/TextField.jsx';
import BackBtn from './BackBtn';
import { motion } from 'framer-motion';
import { Search } from 'react-feather';

import './location.scss';
import './results.scss';


import environment from '../../../utils/environment';

const arr = [];
// const type = 'Law Firm';
let lawFirmId = {};
let lawFirmEmployeeHandles = [];

const Results = (props) => {

  const onSubmit = async (inputs) => {
    if (!inputs.email || inputs.email.length === 0) {
      // props.alert.error("Missing Email!");
      // return console.error('missing message');
    }
    try {
      const payload = { email: inputs.email };
      await http.post(`${environment.resolveApi().rest}/mailing/getupdate/${status}/${arr}/`, payload);
      // console.log(payload);
      // props.alert.success("Message Sent!");
      props.history.push('/');
    } catch (error) {
      // console.error('Sending Error!', error);
    }
  };


  const [profiles, getProfiles] = useState([{}]);
  const [employeeProfiles, setEmployeeProfiles] = useState([{}]);
  const [lawFirms, setFirmInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const { inputs, handleInputChange, handleSubmit } = useForm(onSubmit);

  if (!props.location.state) {
    props.history.push({ pathname: `/search_by_location` })
  } else {
    var { city, status, field, gender, prevPath, type } = props.location.state;
    // onSubmit(inputs)
  }

  // Given a list of employee profiles, use the law_firm_id that associated 
  // with each profile id and output an array of law_firm_ids excluding
  // any duplicate law_firm_ids
  const getLawFirmInfo = async (profiles) => {
    try {
      // console.log(profiles);
      let arr = [];

      for (let i = 0; i < profiles.length; i++) {
        // const response = await http.get(`${environment.resolveApi().rest}/profile/law_firm/${profiles[i].law_firm_info}`);
        // console.log(response.data.law_firm);

        const index = arr.indexOf(profiles[i].law_firm_info);

        // console.log(index);
        // if the firm id is not in the current array
        if (index === -1) {
          arr.push(profiles[i].law_firm_info);
        }
        // console.log('ARRAY #1: ' +arr);
      }

      let lawFirmsArr = [];

      for (let i = 0; i < arr.length; i++) {
        const response = await http.get(`${environment.resolveApi().rest}/profile/law_firm/${arr[i]}`);
        lawFirmsArr.push(response.data.law_firm);
        // console.log(lawFirmsArr);
      }

      setFirmInfo(lawFirmsArr);

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {


    const getList = async () => {
      try {
        arr[0] = field
        const response = await http.get(`${environment.resolveApi().rest}/profile/search/${city}/${status}/${arr}/${gender}`);
        getProfiles(response.data.profiles);
      } catch (error) {
        // console.log(error);
      }
    };

    const getEmployeeList = async () => {
      try {
        arr.push(field);
        // console.log(arr);
        const response = await http.get(`${environment.resolveApi().rest}/profile/searchEmployees/${city}/${status}/${arr}/${gender}`);
        setEmployeeProfiles(response.data.employee_profiles);
        getLawFirmInfo(response.data.employee_profiles);
      } catch (error) {
        // console.log(error);
      }
    };


    if (type !== 'Law Firm') {
      setLoading(true);
      getList();
      setLoading(false);
    } else {
      setLoading(true);
      getEmployeeList();
      setLoading(false);
    }

  }, [setLoading, type, city, field, gender, status])


  const onSubmitFields = () => {

    props.history.push({ pathname: `/search_firm`, state: { lawFirmId: lawFirmId, lawFirmEmployeeHandles: lawFirmEmployeeHandles } });
  };

  const btnClick = (event) => {
    if (event) event.preventDefault();
    lawFirmId = event.currentTarget.value;

    // pass the array of profile handles that were found in the search for that exact law firm
    // console.log(employeeProfiles);

    // 1) Loop through the employeeProfiles array
    // 2) Retrieve the employees associated with the exact same law firm
    // 3) Put them a seperate list

    for (let i = 0; i < employeeProfiles.length; i++) {

      //if the employee is in the law firm that was clicked on
      if (employeeProfiles[i].law_firm_info === lawFirmId) {
        lawFirmEmployeeHandles.push(employeeProfiles[i].handle);
        // console.log(employeeProfiles[i]);
      }

    }
    // console.log('Law firm employees: ' + lawFirmEmployeeHandles)
    onSubmitFields();
  }


  return (
    <div>
      <div className="search-header"><a href="https://counselconnect.ca/"><img className="lawyer-search-logo" src={require('./images/cc-search-logo.svg')} alt="counsel-connect-log" /></a></div>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} style={{ backgroundColor: "#f6f0f0" }} >
        <BackBtn importantProps={{ prevPath }} history={props.history} />
        <span className="search-results-label">Search Results</span>

        <div className="Results" >

          {profiles.length > 0 ?
            <div >
                  {type === 'Law Firm' && !loading ?
                    <div  >

                      {lawFirms.length > 0 ?
                        <div >

                          {/* BANNER ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ----  */}
                          <div className="search-banner">
                            <img className="search-banner-img" src={require('./images/banner-firm.svg')} alt="" />
                              <div className="search-banner-results-txt-wrap"> {/* TITLE ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- */}
                                  {/* <Search size={70} color="#d0021b" style={{ marginBottom: "20px" }} /><br></br> */}
                                    <span className="result-title">Law Firms in {city}</span>
                                    <span className="result-subtitle">Search result based on the following criteria:</span>
                                    <p className="result-paragraph"><strong>Law Fields</strong> <i className="fas fa-chevron-right"></i> {field}</p>
                                    <p className="result-paragraph"><strong>Gender</strong>  <i className="fas fa-chevron-right"></i> {gender}</p> 
                                </div>
                          </div>

                          
                          <div className="law-firms-boxes-wrap">
                            {lawFirms.map((firm) => (
                              <div>
                                <button style={{ height: '220px', maxWidth: '300px' }} className="status-search-lawyer-button" onClick={btnClick} value={firm._id}>
                                  <img style={{ marginTop: '10px', maxWidth: '140px' }} src={firm.companyLogo} alt="company logo" />
                                  <p style={{ fontWeight: '200', fontSize: '16px', marginTop: '1em' }}>{firm.address}</p>
                                  <p style={{ fontWeight: '200', fontSize: '14px' }}>{firm.postalcode}</p>
                                </button>
                              </div>
                            )
                            )}
                          </div>

                        </div>
                        :
                        <div className="result-empty-container" style={{ height: '10rem' }}>
                          <h1 className="location-search-title no-result-title">{`Sorry, currently no ${field} ${status}'s in ${city}`}</h1>

                          <h2 className="get-update-txt">Enter in your email to get updated!</h2>
                          <form className="result-empty-form" onSubmit={handleSubmit}>
                            <TextField
                              className="login-portal-input"
                              title="Email"
                              placeholder=""
                              name="email"
                              type="email"
                              value={inputs.email}
                              onChange={handleInputChange}

                            />
                            <button type="submit" className="edit-avail-update-button">Submit</button>
                          </form>
                          <a href="https://app.counselconnect.ca/search_by_location">
                            <button className="edit-avail-update-button edit-avail-return-button">Return Home</button>
                          </a>

                        </div>
                      }


                    </div>
                    :
                    // SOLE PRACTITIONER
                    <div style={{ display: 'flex', flexFlow: 'column', height: '20rem' }} >

                      <div>


            
                      </div>
                      
          
            {/* BANNER ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ----  */}
              <div className="search-banner">
                <img className="search-banner-img" src={require('./images/banner-sole.svg')} alt="" />
                  <div className="search-banner-results-txt-wrap"> {/* TITLE ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- */}
                      {/* <Search size={70} color="#d0021b" style={{ marginBottom: "20px" }} /><br></br> */}
                        <span className="result-title">{status}s in {city}</span>
                        <span className="result-subtitle">Search result based on the following criteria:</span>
                        <p className="result-paragraph"><strong>Law Fields</strong> <i className="fas fa-chevron-right"></i> {field}</p>
                        <p className="result-paragraph"><strong>Gender</strong>  <i className="fas fa-chevron-right"></i> {gender}</p> 
                    </div>
              </div>


              
            
            {/* PROFILE CARD ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- */}
                {profiles.map((profile, index) => (
                  <div key={index}>

                    <ProfileItem key={index} profileInfo={profile}  />
                  </div>
                )
                )}


                    

                    </div>
                  }

            </div>
            :
            <div className="result-empty-container" style={{ height: '25rem' }}>

              <h1 className="location-search-title"> <Search size={70} color="#d0021b" style={{ marginBottom: "20px" }} /><br></br>{`Sorry, currently no ${field} ${status}'s`}</h1>

              <h2 className="get-update-txt">Enter in your email to get updated!</h2>
              <form className="result-empty-form" onSubmit={handleSubmit}>
                <TextField
                  className="login-portal-input"
                  title="Email"
                  placeholder=""
                  name="email"
                  type="email"
                  value={inputs.email}
                  onChange={handleInputChange}
                />
                <button type="submit" className="edit-avail-update-button">Submit</button>
              </form>
              <a href="https://app.counselconnect.ca/search_by_location">
                <button className="edit-avail-update-button edit-avail-return-button">Return Home</button>
              </a>

            </div>
          }
        </div>
      </motion.div>
    </div >
  );
};

export default Results;
