import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { addAvailability } from '../../../actions/profileActions';
import SelectList from '../../reusable/select_list/SelectList';
import useForm from '../../../hooks/useForm';
import { withAlert } from "react-alert";
import Modal from '../../reusable/Modal';
import "./editAvailabilityModal.scss";

const timeOptions = [
    { label: '* Select', value: 0 },
    { label: 'Closed', value: 'Closed' },
    { label: '6:00 AM', value: '6:00 AM' },
    { label: '7:00 AM', value: '7:00 AM' },
    { label: '8:00 AM', value: '8:00 AM' },
    { label: '9:00 AM', value: '9:00 AM' },
    { label: '10:00 AM', value: '10:00 AM' },
    { label: '11:00 AM', value: '11:00 AM' },
    { label: '12:00 PM', value: '12:00 PM' },
    { label: '1:00 PM', value: '1:00 PM' },
    { label: '2:00 PM', value: '2:00 PM' },
    { label: '3:00 PM', value: '3:00 PM' },
    { label: '4:00 PM', value: '4:00 PM' },
    { label: '5:00 PM', value: '5:00 PM' },
    { label: '6:00 PM', value: '6:00 PM' },
    { label: '7:00 PM', value: '7:00 PM' },
    { label: '8:00 PM', value: '8:00 PM' },
    { label: '9:00 PM', value: '9:00 PM' },
    { label: '10:00 PM', value: '10:00 PM' },
  ];
  
  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  


const EditAvalibilityModal = (props) => {
    const {
        profile: profileState,
        addAvailability: setAvailability,
        modalTitle,
        modalId
     } = props;
     
    const { profile } = profileState;

    const onSubmit = (inputs) => {
        // TODO: validation
    
        const availabilityData = {
          ...days.reduce((acc, day) => ({
            ...acc,
            [day]: {
              from: inputs[`${day}from`],
              to: inputs[`${day}to`],
            },
          }), {}),
        };
    
        console.log(availabilityData);
    
        if (!availabilityData.monday.from || !availabilityData.monday.to ||
            !availabilityData.tuesday.from || !availabilityData.tuesday.to ||
            !availabilityData.wednesday.from || !availabilityData.wednesday.to ||
            !availabilityData.thursday.from || !availabilityData.thursday.to ||
            !availabilityData.friday.from || !availabilityData.friday.to ||
            !availabilityData.saturday.from || !availabilityData.saturday.to ||
            !availabilityData.sunday.from || !availabilityData.sunday.to) 
        {
          props.alert.error("Missing Selection!");
          return;
        }
    
        setAvailability(availabilityData, props.history);
        // setIsEdit(false);
        props.alert.success("Updated!");
      };
      
      const { inputs, setInputs, handleInputChange, handleSubmit } = useForm(onSubmit);

      useEffect(() => {
        if (profile && profile.availability) {
          const availability = {
            ...days.reduce((acc, day) => {
              if (!profile.availability[day]) return acc;
    
              if (profile.availability[day].from) acc[`${day}from`] = profile.availability[day].from;
              if (profile.availability[day].to) acc[`${day}to`] = profile.availability[day].to;
    
              return acc;
            }, {}),
          };
    
          setInputs(availability);
        }
      }, [profile,setInputs]);
    

    return (
        <div className="EditAvalibilityModal">
            <Modal className="edit-avail-modal" title={modalTitle} modalId={modalId}>
                <div className="edit-avail-modal-outer-container">
                    <form className="edit-avail-modal-form" onSubmit={handleSubmit}>
                    {
                    days.map(day => (
                    <div key={day} className="avail-modal-input-container-1">
                      <h3 className="edit-avail-day-text">{day.charAt(0).toUpperCase() + day.slice(1)}</h3>
                      <div className="avail-modal-select-container">
                        <div className="avail-modal-select-list-input-wrap-1">
                          <SelectList
                            className="avail-modal-select-list-input-1"
                            title="From"
                            placeholder=""
                            name={`${day}from`}
                            value={inputs[`${day}from`]}
                            onChange={handleInputChange}
                            options={timeOptions}
                            info=""
                            lawPortal
                          />
                        </div>
                        <div className="avail-modal-select-list-input-wrap-2">
                          <SelectList
                            className="avail-modal-select-list-input-2"
                            title="To"
                            placeholder=""
                            name={`${day}to`}
                            value={inputs[`${day}to`]}
                            onChange={handleInputChange}
                            options={timeOptions}
                            info=""
                            lawPortal
                          />
                        </div>
                      </div>
                      
                    </div>
                    
                  ))
              
                    }
                        <button className="dashboard-row-2-card-1-btn" type="submit">Update</button>
                    

                    </form>
                </div>
            </Modal>
        </div>

    );
}

EditAvalibilityModal.propTypes = {
    addAvailability: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = state => ({
    profile: state.profile,
    errors: state.errors,
  });
  

export default connect(mapStateToProps, { addAvailability })(withRouter(withAlert()(EditAvalibilityModal)));