import React, { useState } from 'react';
import { ArrowLeftCircle } from 'react-feather';

export default (props) => {
    const goBackHandler = () => {
        props.history.push({ pathname: `${props.importantProps.prevPath.pop()}`, state: { ...props.importantProps } });
    }


    // const [backBtnStyle, setBackBtnStyle] = useState({  });
    // const backBtnColorHandler = (evt) => {
    //     setBackBtnStyle({
    //         ...backBtnStyle,
    //         stroke: (backBtnStyle.stroke === '#D80027' ? '#3E2222' : '#D80027')
    //     })
    // }


    return (<div className="search-back-btn" style={{ display: 'inline-block' }} onClick={goBackHandler}> <ArrowLeftCircle className="search-back-arrow-circle" color="#3E2222" size={60}  /></div >)
}