import React from 'react';
import http from '../../../../utils/http';
import SubNavbar from '../../../reusable/sub_navbar/SubNavbar';
import useForm from '../../../../hooks/useForm';
import { withAlert } from "react-alert";
import './Contact.scss';
import environment from '../../../../utils/environment';

const Contact = (props) => {
  const onSubmit = async (inputs) => {
    if (!inputs.message || inputs.message.length === 0) {
      props.alert.error("Missing Message!");
      return console.error('missing message');
    }
    try {
      const payload = { message: inputs.message };
      await http.post(`${environment.resolveApi().rest}/mailing/contact`, payload);
      props.alert.success("Message Sent!");
      props.history.push('/');
    } catch (error) {
      console.error('Sending Error!', error);
    }
  };

  const { inputs, handleInputChange, handleSubmit } = useForm(onSubmit);

  return (
    <div className="Contact">
      <SubNavbar title="Contact" config="info" />

      <div className="contact-form-outer-container">

        <form className="contact-form-container" onSubmit={handleSubmit}>

          <h2 className="contact-form-title">Contact Us</h2>
          <span className="contact-form-subtitle">Have a complaint, constructive comment, or just general feedback? Please don’t hesitate, let us know! We will get back to you at our earliest convenience.</span>

          <div className="contact-form-message-container">
            <textarea
              className="contact-textarea"
              type="text"
              name="message"
              value={inputs.message}
              onChange={handleInputChange}
              autoComplete="off"
            />
          </div>

          <button type="submit" className="contact-form-send-button">Send</button>
        </form>
      </div>
    </div>
  );
};

export default  withAlert()(Contact);
