import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

import './Navbar.scss';

class Navbar extends Component {
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-sm navbar-light  mb-4 navbar-customize">
          <div className="container">
            <a className="navbar-brand" href="https://counselconnect.ca">
              <img className="logo" src={require('../navbar/images/top-navbar-logo.svg')} alt="" />
            </a>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#mobile-nav"
            >
              <i className="navbar-toggler-icon" />

            </button>
            <div className="collapse navbar-collapse" id="mobile-nav">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a className="nav-link" href="https://counselconnect.ca/lawyer-home">
                    <h3>For Lawyers</h3>
                  </a>
                </li>
                <li className="nav-item">
                <a className="nav-link" href="https://app.counselconnect.ca/search_by_location">
                  <h3>Browse</h3>
                </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://counselconnect.ca/about">
                    <h3>About Us</h3>
                  </a>
                </li>
                {/* <li className="nav-item">
                <a className="nav-link" href="http://localhost:3000/signup/request">
            <button className="auth-button">
              Sign Up
            </button>
          </a>
                </li>
                <li className="nav-item">
                <a href="http://localhost:3000/login" className="nav-link" >
            <button className="auth-button">
              Log In
              </button>
            </a>

                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </div>

    );
  }
}


export default Navbar;
