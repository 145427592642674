import React, { useEffect, useState, useCallback } from 'react';
import TYPES from "./Types";
import * as Icon from 'react-feather';
import http from "../../../utils/http";
import environment from "../../../utils/environment";
import './clientList.scss';
import MicroModal from 'micromodal';
import { withAlert } from "react-alert";
import CreateClientModal from './CreateClientModal.jsx';
import Spinner from '../../reusable/spinner/spinner.gif';
import microtip from 'microtip/microtip.css'
import {CopyToClipboard} from 'react-copy-to-clipboard';

const ClientList = (props) => {

  // const [searchInput, setSearchInput] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [clientList, setClientList] = useState([]);
  const [isChecking, setChecking] = useState(false);
  const [copyEmail, setCopyEmail] = useState('Copy Email');

  const onSearch = (event) => {
    setSearchInput(event.target.value);
  }
  

  

  useEffect(() => {

    const getClientList = async () => {
      try {
        const response = await http.get(`${environment.resolveApi().rest}/client/list`);
        setClientList(response.data);
        // console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    setChecking(true);
    getClientList();
    setChecking(false);
  }, []);


  const onNewClientModal = useCallback(() => MicroModal.show('create-client-modal'), []);

  if (isChecking) return <Spinner />;

  if (!isChecking) 
  {
    return (
      <div className="ClientList">
          
          <h1>Clients</h1>

        
          
          { clientList.length > 0 ?

            <div>

          <div className="client-list-table-actions-bar">
              <div className="clients-dropdown-wrap">
                {/* <div className="clients-filter-by-dropdown">Filter <Icon.ChevronDown className="chevron-down-icon" size={21} strokeWidth={2.5} color={"rgba(63,34,34,0.35)"} /></div> */}
                {/* <div className="clients-sort-by-dropdown">Sort By <Icon.ChevronDown className="chevron-down-icon" size={21} strokeWidth={2.5} color={"rgba(63,34,34,0.35)"} /></div> */}
              </div>
              
              <div className="clients-search-add-wrap" >
                <input className="clients-search-field" type="search" placeholder="Search Name..." autoComplete="off" onChange={onSearch}></input>

              <button className="clients-add-button" onClick={onNewClientModal}><Icon.UserPlus className="user-plus-icon" size={38} color={"#C52D29"} /></button>
              </div>
              
          </div>

  

          <table className="table">
              <thead id="table-header">
                <tr>
                  <th>Name</th>
                  <th>Service</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Client Profile</th>
                </tr>
              </thead>
            
              <tbody>

              {/* Map through each client element inside the array */}
              {clientList.map((client, index) => {
                // if the searched input === client[i].name then return a <tr> else return nothing
                const client_name = `${client.first_name} ${client.last_name}`;
              
                if (client_name.toLowerCase().indexOf(searchInput) !== -1 || (client_name.charAt(0).toUpperCase() + client_name.slice(1)).indexOf(searchInput) !== -1 || (client_name.toUpperCase()).indexOf(searchInput) !== -1 ) 
                {
                  return (
                      <tr key={index}>
                        <td><div className="td-1">{client_name}</div></td>
                        <td><div className="td-4">{client.service}</div></td>
                        <td>
                        <CopyToClipboard text={client.email} >
                          <div className="td-2"  aria-label={copyEmail} data-microtip-position="left" role="tooltip" onClick={() => {setCopyEmail('Copied 🎉')}}>{client.email}</div>
                        </CopyToClipboard>
                          </td>
                        
                        <td><a className="td-3-link" href={`tel:${client.phone}`}><div className="td-3" aria-label={'Call 📞'} data-microtip-position="left" role="tooltip">{client.phone} <Icon.Phone className="client-phone-icon" size={24} color={"#FFC700"} /></div></a></td>
                        <td><div className="td-5" onClick={()=>{props.history.push(`client/${client.email}`)}}>View <Icon.ArrowRightCircle className="client-arrow-right-icon" size={24} color={"rgb(235, 62, 79)"} /></div></td>
                        {/* <td style={{background: TYPES.StatusTypes.statuses[client.case_status].background  , color: TYPES.StatusTypes.statuses[client.case_status].color , fontWeight: "700"}}>{client.case_status}</td> */}
                      </tr>
                          
                    );
                  }
                  return (
                    ''
                  );
                
                  }
              )}

              </tbody>
            </table>

            </div>
        : 
        
        <div>

  <div className="client-list-no-clients-conatiner">
        <div className="client-list-no-clients-title-wrap">
          <span className="client-list-no-clients-title">You have added no clients</span>
          <Icon.Frown className="no-clients-frown-icon" size={42} strokeWidth={2.5} color={"#402223"} />
      
        </div>
          <div className="client-list-no-clients-btn-wrap">
        {/* <button className="dashboard-row-2-card-1-btn no-clients-add-btn"  type="button"  >Import Clients</button> */}
        <button className="dashboard-row-2-card-1-btn no-clients-add-btn-2"  type="button"  onClick={onNewClientModal}>Add Client</button>
        </div>
          <img  className="client-list-table-no-clients-illustration" src={require("./images/clients-illustration.svg")} alt="company logo"/>

  </div>

        </div> }


        <CreateClientModal
              modalId="create-client-modal"
              modalTitle="Create Client"
              history={props.history}
            {...props}
        />

      </div>
    );
  }
};


export default withAlert()(ClientList);
