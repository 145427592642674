import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import http from '../../../utils/http';
import './profileitem.scss';
import trustedIcon from '../law_search/images/trust-badge.svg';
import environment from '../../../utils/environment';

const ProfileItem = (props) => {
 
    const { profileInfo } = props;
    const handle = profileInfo.handle;
    // const lawTitle = `${profileInfo.status}`

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
 
  
    useEffect( () => {
      const getUserInfoFromHandle = async () => {
        try {
          const response = await http.get(`${environment.resolveApi().rest}/profile/${handle}/info`);
          setFirstName(response.data.firstName);
          setLastName(response.data.lastName);
        } catch (error) {
          // console.log(error);
        }
      };
      getUserInfoFromHandle();

     
   
      // fetchLawFirmInfo(profile.lawFirmName);
    }, [handle]);

   
    return (
      <div className="profile-card-p">
        <div className="card-p">
      
          <div className="profile-card-image">
            

            { profileInfo.pictureURL ?
                <img className="rounded-circle profile-card-img" src={`${profileInfo.pictureURL}`} alt="" />
            :
              <div>
                  <div className="rounded-circle default-pic-background" />
                  {/* <img style={{maxWidth: '150px', marginTop: '2.5em'}} src={lawFirmInfo.pictureURL}/> */}
              </div>
            }

              <h3 className="profile-card-title-mobile">{`${firstName} ${lastName}`}</h3>

              <div className="cc-trusted-img-mobile-wrap">
                <img className="cc-trusted-img-mobile" src={trustedIcon} alt="" />
                <span className="cc-trusted-mobile">COUNSEL TRUSTED</span>
              </div>
          </div>

          <div className="card-info">
  

           <div className="profile-card-title">
              <h3 className="profile-card-title">{`${firstName} ${lastName}`}</h3>
              {/* <p>{lawTitle}</p> */}
              {/* <span className="profile-fields">{`${profile.field} `}</span> */}
              {/* <hr /> */}

              <div className="profile-card-info-wrapper">
                  <div className="profile-card-info-2">
                      <span className="profile-card-info-addr-label">
                        <i class="fas fa-location-arrow" style={{ fontSize: '1.1em', marginRight: '1em', color: '#A38C8C' }}></i>
                          Address
                      </span>
                    
                      <p className="profile-card-info-address-value">{profileInfo.address}</p>
                  </div>

                  <div className="profile-card-info">
                    <span className="profile-card-info-language-label">
                      <i className="fas fa-language" style={{ fontSize: '1.3em', marginRight: '.5em', color: '#A38C8C' }} />
                      Languages
                    </span>
                    <p className="profile-card-info-language-value">{profileInfo.languages}</p>
                  
                  </div>
              </div>


            </div> 
          
          <div className="viewlaw-profile-btn-wrap">
            <div>
                <span className="cc-trusted"><img style={{width: '20px', marginBottom: '5px', marginRight: '8px'}} src={trustedIcon} alt="" />COUNSELCONNECT TRUSTED</span>
            </div>

            <div className="profile-card-info-wrapper-mobile">
                  <div className="profile-card-info profile-card-info-2">
                      <h4>
                        <i class="fas fa-location-arrow" style={{ fontSize: '1.1em', marginRight: '1em', color: '#A38C8C' }}></i>
                          Address
                      </h4>
                      <p className="profile-card-info-address-value">{profileInfo.address}</p>
                    
                  </div>

                  <div className="profile-card-info">
                    <h4>
                      <i className="fas fa-language" style={{ fontSize: '1.3em', marginRight: '.5em', color: '#A38C8C' }} />
                      Languages
                    </h4>
                    <p className="profile-card-info-language-value">{profileInfo.languages}</p>
                  
                  </div>
              </div>
            
            <div className="profile-card-button">
              <Link to={{pathname: `/profile/${handle}`}}>
                <button>
                View full profile
                </button>
              </Link>
            </div>
        </div>

          </div>
         
        </div>
         
      </div>
    );
  
}


export default ProfileItem;
