import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addAvailability } from '../../../actions/profileActions';
import SubNavbar from '../../reusable/sub_navbar/SubNavbar';
import SelectList from '../../reusable/select_list/SelectList';
import useForm from '../../../hooks/useForm';
import { withAlert } from "react-alert";

import './addAvailability.scss';
import '../profile_info/EditProfile.scss';

const timeOptions = [
  { label: '* Select Time', value: 0 },
  { label: 'Closed', value: 'Closed' },
  { label: '6:00 AM', value: '6:00 AM' },
  { label: '7:00 AM', value: '7:00 AM' },
  { label: '8:00 AM', value: '8:00 AM' },
  { label: '9:00 AM', value: '9:00 AM' },
  { label: '10:00 AM', value: '10:00 AM' },
  { label: '11:00 AM', value: '11:00 AM' },
  { label: '12:00 PM', value: '12:00 PM' },
  { label: '1:00 PM', value: '1:00 PM' },
  { label: '2:00 PM', value: '2:00 PM' },
  { label: '3:00 PM', value: '3:00 PM' },
  { label: '4:00 PM', value: '4:00 PM' },
  { label: '5:00 PM', value: '5:00 PM' },
  { label: '6:00 PM', value: '6:00 PM' },
  { label: '7:00 PM', value: '7:00 PM' },
  { label: '8:00 PM', value: '8:00 PM' },
  { label: '9:00 PM', value: '9:00 PM' },
  { label: '10:00 PM', value: '10:00 PM' },
];

const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const EditAvailability = (props) => {
  const {
    profile: profileState,
    addAvailability: setAvailability,
  } = props;

  const { loading, profile } = profileState;

  const [isEdit, setIsEdit] = useState(false);

  const onSubmit = (inputs) => {
    // TODO: validation

    const availabilityData = {
      ...days.reduce((acc, day) => ({
        ...acc,
        [day]: {
          from: inputs[`${day}from`],
          to: inputs[`${day}to`],
        },
      }), {}),
    };

    console.log(availabilityData);

    if (!availabilityData.monday.from || !availabilityData.monday.to ||
        !availabilityData.tuesday.from || !availabilityData.tuesday.to ||
        !availabilityData.wednesday.from || !availabilityData.wednesday.to ||
        !availabilityData.thursday.from || !availabilityData.thursday.to ||
        !availabilityData.friday.from || !availabilityData.friday.to ||
        !availabilityData.saturday.from || !availabilityData.saturday.to ||
        !availabilityData.sunday.from || !availabilityData.sunday.to) 
    {
      props.alert.error("Missing Selection!");
      return;
    }

    setAvailability(availabilityData, props.history);
    setIsEdit(false);
    props.alert.success("Availability Updated!");
  };

  const { inputs, setInputs, handleInputChange, handleSubmit } = useForm(onSubmit);

  useEffect(() => {
    if (profile && profile.availability) {
      const availability = {
        ...days.reduce((acc, day) => {
          if (!profile.availability[day]) return acc;

          if (profile.availability[day].from) acc[`${day}from`] = profile.availability[day].from;
          if (profile.availability[day].to) acc[`${day}to`] = profile.availability[day].to;

          return acc;
        }, {}),
      };

      setInputs(availability);
    }
  }, [profile,setInputs]);

  // early returns
  if (loading) return <div>loading</div>;
  if (!profile) return <div>no profile</div>;


  return (
    <div className="AddAvailability">
      <SubNavbar title="Edit Availability" config="profile" />

      {
        isEdit ? (
          <div className="edit-avail-content-container">

            <div>
              <form className="edit-avail-form-container" onSubmit={handleSubmit}>
                {
                  days.map(day => (
                    <div key={day} className="edit-avail-input-container-1">
                      <h3 className="edit-avail-day-text">{day.charAt(0).toUpperCase() + day.slice(1)}</h3>
                      <div className="edit-avail-select-container">
                        <div className="edit-avail-select-input-1">
                          <SelectList
                            className="law-portal-select-list-input"
                            title="From"
                            placeholder=""
                            name={`${day}from`}
                            value={inputs[`${day}from`]}
                            onChange={handleInputChange}
                            options={timeOptions}
                            info=""
                            lawPortal
                          />
                        </div>
                        <div className="edit-avail-select-input-2">
                          <SelectList
                            className="law-portal-select-list-input"
                            title="To"
                            placeholder=""
                            name={`${day}to`}
                            value={inputs[`${day}to`]}
                            onChange={handleInputChange}
                            options={timeOptions}
                            info=""
                            lawPortal
                          />
                        </div>
                      </div>
                    </div>
                  ))
                }
              <div className="edit-avail-update-button-container">
                <button className="edit-profile-exit-button" type="submit" onClick={() => setIsEdit(false)}>EXIT</button>
                <button type="submit" className="edit-avail-update-button">UPDATE</button>
              </div>
              </form> 
            </div>
          </div>
        ) : (
          <div className="availability-view-content-container">
            <div className="profile-info-tag-container">
              <div className="profile-info-tag-content-container">
                <h6>Availability</h6>
                <hr />
                <div className="availaibility-tag-heading-container">
                  {
                    profile.availability ? days.map((day) => {
                      const from = profile.availability[day] ? profile.availability[day].from : 'n/a';
                      const to = profile.availability[day] ? profile.availability[day].to : 'n/a';
                      return (
                        <div key={day}>
                          {  from === "Closed" && to === "Closed" ?
                           <div>
                              <strong>{day.charAt(0).toUpperCase() + day.slice(1)}</strong>
                              <h5>{`${from}`}</h5> 
                           </div>   
                              :
                           <div>   
                              <strong>{day.charAt(0).toUpperCase() + day.slice(1)}</strong>
                              <h5>{`${from} - ${to}`}</h5>
                            </div>
                          }
                        </div>
                      );
                    }) : 'no availability data'
                  }
                </div>
              </div>
            </div>

            <div className="availability-view-content-button-container">
              <button className="availability-view-content-button" onClick={() => setIsEdit(true)}>EDIT</button>
            </div>

          </div>
        )
      }

    </div>
  );
};


EditAvailability.propTypes = {
  addAvailability: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
  errors: state.errors,
});

export default connect(mapStateToProps, { addAvailability })(withRouter(withAlert()(EditAvailability)));
