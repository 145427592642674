import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { Provider } from 'react-redux';
import { checkLogin } from './actions/authActions';
import store from './utils/store';
import Spinner from './components/reusable/spinner/Spinner';
import SignUp from './components/auth/SignUp';
import Login from './components/auth/Login';
import SignUpRequest from './components/auth/SignUpRequest'
import SignUpRequestSuccess from './components/auth/SignUpRequestSuccess.jsx';
import Main from './Main';
import PrivateRoute from './components/reusable/PrivateRoute';
import Profile from './components/lawyer_search_app/law_public_profile/SocialProfile';
import FirmEmpLawProfile from './components/lawyer_search_app/law_public_profile/FirmEmpLawProfile';

import Location from './components/lawyer_search_app/law_search/Location.jsx';
import Status from './components/lawyer_search_app/law_search/Status.jsx';
import Lawyer from './components/lawyer_search_app/law_search/Lawyer.jsx';
import Paralegal from './components/lawyer_search_app/law_search/Paralegal.jsx';
import Gender from './components/lawyer_search_app/law_search/Gender.jsx';
import Type from './components/lawyer_search_app/law_search/Type.jsx';
import Results from './components/lawyer_search_app/law_search/Results.jsx';

import LawFirm from './components/lawyer_search_app/law_search/LawFirm.jsx';

import { positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import PasswordResetRequest from './components/auth/PasswordResetRequest.jsx';
import ResetPassword from './components/auth/ResetPassword.jsx';


// optional cofiguration
const options = {
    position: positions.TOP_RIGHT,
    timeout: 3000,
    offset: "50px",
    transition: 'fade',
    containerStyle: {
        zIndex: 9999
    }
  };
  

const App = () => {
  const [isChecking, setChecking] = useState(true);

  // check if the server is up
  useEffect(() => {
    (async function () {
      try {
        await store.dispatch(checkLogin());
      } catch (err) {
        console.log(err.message)
      }

      setChecking(false);
    })()
  }, []);

  if (isChecking) return <Spinner />;

  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <Provider store={store}>
        <Router>
          <AnimatePresence>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/signup/request" component={SignUpRequest} />
              <Route exact path="/signup/request/success" component={SignUpRequestSuccess} />
              <Route exact path="/signup/:shortId" component={SignUp} />
              <Route exact path="/reset-password" component={PasswordResetRequest} />
              <Route exact path="/reset-password/:shortId" component={ResetPassword} />


              <Route exact path="/search_by_location" component={Location} />
              <Route exact path="/search_by_status" component={Status} />
              <Route exact path="/search_by_Lawyer" component={Lawyer} />
              <Route exact path="/search_by_Paralegal" component={Paralegal} />
              <Route exact path="/search_by_gender" component={Gender} />
              <Route exact path="/search_by_type" component={Type} />
              <Route exact path="/search_firm" component={LawFirm} />


              <Route exact path="/search_results/:city/:status/:field/:gender/:type" component={Results} />
              <Route exact path="/profile/:handle" component={Profile} />
              <Route exact path="/profile/employee/:handle" component={FirmEmpLawProfile} />
              <PrivateRoute path="/" component={Main} />
            </Switch>
          </AnimatePresence>
        </Router>
      </Provider>
    </AlertProvider>
  );
};

export default App;
