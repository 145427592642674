import React, { useEffect, useState, useCallback } from 'react';
import TYPES from "./Types";
import * as Icon from 'react-feather';
import http from "../../../utils/http";
import environment from "../../../utils/environment";
import './clientDetails.scss';
import MicroModal from 'micromodal';
import { withAlert } from "react-alert";
import Spinner from '../../reusable/spinner/spinner.gif';
import EditClientModal from './EditClientModal.jsx';
import microtip from 'microtip/microtip.css'
import {CopyToClipboard} from 'react-copy-to-clipboard';

const ClientDetails = (props) => {
    const { email } = props.match.params;

    const [clientProfile, setClientProfile] = useState([]);
    const [isChecking, setChecking] = useState(false);
    const [copyEmail, setCopyEmail] = useState('Copy Email');

    useEffect(() => {
        const getClientProfile = async () => {
            try {
                const response = await http.get(`${environment.resolveApi().rest}/client/${email}`);
                setClientProfile(response.data);
            } catch (error) {
                console.log(error);
                props.history.push('/');
            }
        };
        
        setChecking(true);
        getClientProfile();
        setChecking(false);
    }, []);

    const client_name = `${clientProfile.first_name} ${clientProfile.last_name}`;
    const onNewClientModal = useCallback(() => MicroModal.show('edit-client-modal'), []);

    if (isChecking) return <Spinner />;

    if (!isChecking) 
    {
        return (
            <div className="ClientDetails">
                <h6>Clients</h6>
                <div className="client-details-title-wrap">
                    <Icon.ArrowLeftCircle className="client-details-arrow-back-btn" size={38} strokeWidth={2.5} color={"#3E2222"} onClick={()=>{props.history.push('/client-list')}} />
                    <h1>{client_name}</h1>
                </div>

            <table className="table">
                <thead id="table-header">
                    <tr>
                        <th>Name</th>
                        <th>Service</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Client Profile</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td><div className="td-1">{client_name}</div></td>
                        <td><div className="td-4">{clientProfile.service}</div></td>
                        <td>
                        <CopyToClipboard text={clientProfile.email} >
                            <div className="td-2" aria-label={copyEmail} data-microtip-position="left" role="tooltip" onClick={() => {setCopyEmail('Copied 🎉')}}>{clientProfile.email}</div>
                       </CopyToClipboard>
                        </td>
                       
                        <td><a className="td-3-link" href={`tel:${clientProfile.phone}`}><div className="td-3" aria-label={'Call 📞'} data-microtip-position="left" role="tooltip">{clientProfile.phone} <Icon.Phone className="client-phone-icon" size={24} color={"#FFC700"} /></div></a></td>
                        
                        <td><div className="td-5" onClick={onNewClientModal} >Edit Profile <Icon.Edit className="client-arrow-right-icon" size={24} color={"rgb(235, 62, 79)"} /></div></td>
                        {/* <td style={{background: TYPES.StatusTypes.statuses[client.case_status].background  , color: TYPES.StatusTypes.statuses[client.case_status].color , fontWeight: "700"}}>{client.case_status}</td> */}
                    </tr>
                </tbody>
          </table>
            

        <EditClientModal
            modalId="edit-client-modal"
            modalTitle="Edit Client"
            history={props.history}
            clientInfo={clientProfile}
          {...props}
        />

                
            </div>
        );
    }
};


export default withAlert()(ClientDetails);
