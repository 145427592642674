import React, { useState, useEffect } from 'react';
import http from '../../../utils/http';
import environment from '../../../utils/environment';
import EmployeeProfileItem from '../law_profile_cards/EmployeeProfileItem';
import './location.scss';

const LawFirm = (props) => {

    if (!props.location.state) {
        props.history.push({ pathname: `/search_by_location` })
    } else {
        var { lawFirmId, lawFirmEmployeeHandles } = props.location.state;
    }
    // const { lawFirmId, lawFirmEmployeeHandles } = props.location.state;

    const [lawFirmInfo, setFirmInfo] = useState([]);
    const [employees, setEmployeeInfo] = useState([]);


    useEffect(() => {

        const getLawFirmInfo = async () => {
            const response = await http.get(`${environment.resolveApi().rest}/profile/law_firm/${lawFirmId}`);
            setFirmInfo(response.data.law_firm);
        };

        const getEmployees = async () => {
            let arr = [];

            for (let i = 0; i < lawFirmEmployeeHandles.length; i++) {
                const response = await http.get(`${environment.resolveApi().rest}/profile/law_firm/employee/${lawFirmEmployeeHandles[i]}`);
                // console.log(response.data.profile);
                arr.push(response.data.profile);
            }
            setEmployeeInfo(arr);
        };
        getLawFirmInfo();
        getEmployees();
    }, [lawFirmEmployeeHandles,lawFirmId])




    return (
        <div className="Location" style={{ height: '100%' }} >
           <div className="search-header"><a href="https://counselconnect.ca/"><img className="lawyer-search-logo" src={require('./images/cc-search-logo.svg')} alt="counsel-connect-log" /></a></div>

        <div className="firm-page-wrapper">

        <div>
            {employees.length > 0 ?
                (employees.map((employee, index) => (
                    <EmployeeProfileItem key={index} profile={employee} lawFirmInfo={lawFirmInfo} />
                )
                ))
            :
                    ''
            }
        </div>



              {/* BANNER ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ----  */}
        <div className="search-banner">
          
                <div className="search-banner-results-txt-wrap"> {/* TITLE ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- */}
                    {/* <Search size={70} color="#d0021b" style={{ marginBottom: "20px" }} /><br></br> */}
                    <img className="law-firm-search-img" src={lawFirmInfo.companyLogo} alt={"Company Logo"} />
                    <span className="result-title">Law Firm in {lawFirmInfo.city}</span>
                    <span className="result-subtitle">Law firm details:</span>
                    <p className="result-paragraph"><strong>Address</strong> <i className="fas fa-chevron-right"></i> {lawFirmInfo.address}</p>
                    <p className="result-paragraph"><strong>Postal Code</strong> <i className="fas fa-chevron-right"></i> {lawFirmInfo.postalcode}</p>
                </div>
        </div>


        </div>


        </div>
    );
};

export default LawFirm;
