const currentEnvironment = "stage";

const env = {
    development: {
        rest: "http://localhost:5000/api"
    },
    stage: {
        rest: "https://api.hadiaamir.me/api"
    },
    production: {
        rest: "https://api.counselconnect.ca/api"
    }
}

const environment = {

    /**
     * Returns the environment endpoints set above
     *
     * @return {String}
     */
    resolveApi: () => {
        return env[currentEnvironment];
    }

}   

export default environment;
