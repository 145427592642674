import React, { useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MicroModal from 'micromodal';
import moment from 'moment';

import CredentailsTable from '../profile_dashboard/CredentialsTable.jsx';
import { getCurrentProfile, deleteExperience, deleteEducation } from '../../../actions/profileActions';

import AddExperienceModal from './add_experience/AddExperienceModal';
import AddEducationModal from './add_education/AddEducationModal';

import './credentials.css';
import './add_experience/addExperienceModal.css';

import SubNavbar from '../../reusable/sub_navbar/SubNavbar';

function Credentials(props) {
  const {
    getCurrentProfile,
    deleteEducation,
    deleteExperience,
    profile: profileState,
  } = props;

  // runs only on mount
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);

  const onNewExperience = useCallback(() => MicroModal.show('add-experience'), []);
  const onNewEducation = useCallback(() => MicroModal.show('add-education'), []);

  const { experience = [], education = [] } = profileState.profile || {};

  return (
    <div className="Credentials">
      <SubNavbar title="Credentials" config="profile" />

      <div className="creds-outer-container">
        <div className="credentials-container">
          <div className="page-header">
            <h2 style={{ fontFamily: 'Roboto', marginRight: '1em', fontWeight: '300' }}>
              Experience
            </h2>

            <div className="auth-add-button">
              <button
                onClick={onNewExperience}
                type="button"
                id="add-button"
              >
                +
              </button>
            </div>
          </div>

          <CredentailsTable
            columns={['Company', 'Title', 'Years']}
            items={experience}
            onDeleteItem={deleteExperience}
            getRowFromItem={useCallback((item) => {
              const { company, title, from, to, current } = item;

              const fromDate = moment(from).format('YYYY/MM/DD');
              const toDate = to && !current ? moment(to).format('YYYY/MM/DD') : 'Present';

              const years = `${fromDate} - ${toDate}`;

              return [company, title, years];
            }, [])}
          />

          <AddExperienceModal
            modalId="add-experience"
            modalTitle="Add Experience"
            {...props}
          />
        </div>

        <div className="credentials-container">
          <div className="page-header">
            <h2 style={{ fontFamily: 'Roboto', marginRight: '1em', fontWeight: '300' }}>
              Education
            </h2>

            <div className="auth-add-button">
              <button
                onClick={onNewEducation}
                type="button"
                id="add-button"
              >
                +
              </button>
            </div>
          </div>

          <CredentailsTable
            columns={['School', 'Degree', 'Years']}
            items={education}
            onDeleteItem={deleteEducation}
            getRowFromItem={useCallback((item) => {
              const { school, degree, from, to, current } = item;

              const fromDate = moment(from).format('YYYY/MM/DD');
              const toDate = to && !current ? moment(to).format('YYYY/MM/DD') : 'Present';

              const years = `${fromDate} - ${toDate}`;

              return [school, degree, years];
            }, [])}
          />

          <AddEducationModal
            modalId="add-education"
            modalTitle="Add Education"
            {...props}
          />
        </div>
      </div>
    </div>
  );
}

Credentials.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

Credentials.defaultProps = {
  profile: {},
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth,
});


export default connect(
  mapStateToProps,
  { getCurrentProfile, deleteEducation, deleteExperience },
)(withRouter(Credentials));
