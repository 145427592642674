import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import http from '../../../utils/http';
import './profileitem.scss';
import trustedIcon from '../law_search/images/trust-badge.svg';
import environment from '../../../utils/environment';

const EmployeeProfileItem = (props) => {

  const { profile } = props;
  const handle = profile.handle;
  const lawTitle = profile.law_title;

  const [loading, setLoading] = useState(false);

  return (
    <div className="profile-card-p employee-card">

      {!loading ?
        <div className="card-p">

          <div className="profile-card-image">
              <div className="emp-card-cc-trusted-wrap">
                <img className="emp-card-cc-trusted-img" src={trustedIcon} alt="" />
                <span className="emp-card-cc-trusted-txt">COUNSELCONNECT TRUSTED</span>
              </div>


            <img className="rounded-circle employee-card-img" src={`${profile.pictureURL}`} alt="" />

          
            
          </div>

          
          <div className="card-info">


          <div className="profile-card-title">
          <h3 className="profile-card-title">{`${profile.first_name} ${profile.last_name}`}</h3>
              <p className="profile-card-info-language-value">{lawTitle}</p>

              <div className="profile-card-info">
              <span className="profile-card-info-language-label">
                <i className="fas fa-language" style={{ fontSize: '1.3em', marginRight: '.5em', color: '#A38C8C' }} />
                Languages
              </span>
              <p className="profile-card-info-language-value">{profile.languages}</p>
            
            </div>

            </div>
            
            <div className="viewlaw-profile-btn-wrap">
            <div className="profile-card-info-wrapper-mobile">
                  <div className="profile-card-info profile-card-info-2 emp-card-address">
                      <h4>
                        <i class="fas fa-location-arrow" style={{ fontSize: '1.1em', marginRight: '1em', color: '#A38C8C' }}></i>
                          Address
                      </h4>
                      {/* <p>{profile.address}</p> */}
                      <p>{'3182 Terry Fox Dr.'}</p>
                  </div>

                  <div className="profile-card-info">
                  <h3 className="profile-card-title-mobile">{`${profile.first_name} ${profile.last_name}`}</h3>

                    <h4>
                      <i className="fas fa-language" style={{ fontSize: '1.3em', marginRight: '.5em', color: '#A38C8C' }} />
                      Languages
                    </h4>
                    <p className="profile-card-info-language-value">{profile.languages}</p>
                  
                  </div>
              </div>

          


            <div className="profile-card-button">
              <Link to={{ pathname: `/profile/employee/${handle}` }}>
                <button>
                  View Profile
                </button>
              </Link>
            </div>

            </div>
          </div>

        </div>

        :

        <div>
          <p>Loading ...</p>
        </div>
      }
    </div>
  );

}


export default EmployeeProfileItem;
