import React, { useState } from 'react';
import './location.scss';
import { motion } from 'framer-motion';
import Tooltip from 'react-tooltip-lite';

import BackBtn from './BackBtn';
import { Info } from 'react-feather';

const lawyerFields = [
  { label: 'Criminal Law', value: 'Criminal Law' },
  { label: 'Commercial Law', value: 'Commercial Law' },
  { label: 'Civil Law', value: 'Civil Law' },
  { label: 'Employment Law', value: 'Employment Law' },
  { label: 'Family Law', value: 'Family Law' },
  { label: 'Immigration Law', value: 'Immigration Law' },
  { label: 'Real Estate', value: 'Real Estate' },
];

const arr = [];

const Lawyer = (props) => {
  if (!props.location.state) {
    props.history.push({ pathname: `/search_by_location` })
  } else {
    var { city, status, prevPath } = props.location.state;
  }

  const [lawFields, setLawFields] = useState(arr);

  const onSubmitFields = () => {

    if (arr.length === 0) {
      props.alert.error("Select a category!");
    } else {
      setLawFields(arr);

      props.history.push({ pathname: `/search_by_gender`, state: { city: city, status: status, field: lawFields.pop(), prevPath: [...prevPath, props.location.pathname] } });
    }
  };

  const btnClick = (event) => {
    if (event) event.preventDefault();
    // const index = arr.indexOf(event.currentTarget.value);
    arr.push(event.currentTarget.value);
    onSubmitFields();
    // if (index > -1) {
    //   arr.splice(index, 1);
    // } else {
    //   arr.push(event.currentTarget.value);
    // }
  };



  return (
    <div>
      <div className="search-header"><a href="https://counselconnect.ca/"><img className="lawyer-search-logo" src={require('./images/cc-search-logo.svg')} alt="counsel-connect-log" /></a></div>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} style={{ backgroundColor: "#f6f0f0" }} >
        <BackBtn importantProps={{ prevPath: prevPath, city, status, field: lawFields.pop() }} history={props.history} />
        <div className="Lawyer-Search">

        

          <img className="location-search-header-icon" src={require('./images/gradle.svg')} alt="" />

          <h1 className="location-search-title">Lawyer Categories</h1>
          <p className="location-search-subtitle">Select the law field that best fits your needs.</p>
          <div className="law-field-options">
            {lawyerFields.map((field, index) => (
              <div key={index}>
                <button type="submit" className="status-search-lawyer-button" onClick={btnClick} key={index.id} value={field.value}>{field.value}
                  <span style={{ marginLeft: "10px" }}>
                  
                  </span>

                </button>
              </div>
            ))}

          </div>
          <div className="location-page-num">3 of 5</div>
        </div>
      </motion.div >
    </div>
  );
};

export default Lawyer;