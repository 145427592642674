const profile = [
  {
    to: '/edit-profile',
    title: 'Profile',
    subtitle: 'Edit your profile information',
    icon: require('./images/user-icon.svg'),
  },
  {
    to: '/add-experience',
    title: 'Credentials',
    subtitle: 'Add your professional experience',
    icon: require('./images/grad-icon.svg'),
  },
  {
    to: '/add-availability',
    title: 'Availability',
    subtitle: 'Edit the times you are available',
    icon: require('./images/calendar-icon.svg'),
  },
];

const info = [
  {
    to: '/account',
    title: 'Account Info',
    subtitle: 'General account information',
    icon: require('./images/user-icon.svg'),
  },
  {
    to: '/contact',
    title: 'Contact',
    subtitle: 'Have any questions?',
    icon: require('./images/plane-icon.svg'),
  },
];

export default { info, profile };
