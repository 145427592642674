import React, { useState } from 'react';
import useForm from '../../../../hooks/useForm';
import TextField from '../../../reusable/text_field/TextField';

import './AccountInfo.scss';
import http from '../../../../utils/http';
import { withAlert } from "react-alert";
import environment from '../../../../utils/environment';

const PasswordReset = (props) => {

  const [isEdit, setIsEdit] = useState(false);
 
  const onSubmit = async (inputs) => {
    if ( !(inputs.newPassword === inputs.newPassword2) ) {
      props.alert.error("Password Don't Match!");
      return;
    }
    try {
      const newPasswordData = { 
        oldPassword: inputs.oldPassword,
        password: inputs.newPassword, 
      };
  
      await http.post(`${environment.resolveApi().rest}/user/password`, newPasswordData );
      props.alert.success("Password Updated!");
      setIsEdit(false); 
    } catch (error) {
      console.log(error);
      if(error.message) {
        props.alert.error(error.message);
      }
      if(error.password) {
        props.alert.error("Missing Input!");
      }
    }
  };

  const { inputs, handleInputChange, handleSubmit } = useForm(onSubmit);


  return (
    <div className="AccountInfo">
    
        <form onSubmit={handleSubmit}>
          <div className="acc-info-password">
            <strong>Password</strong>
            { !isEdit
                ? (<button type="submit" onClick={() => { setIsEdit(true); }}>Reset</button>)
                : (
                  <div className="acc-info-edit-email-txt-field">
                    <div>
                    <TextField
                      className="lw-portal-text-input"
                      title="Old Password"
                      placeholder=""
                      type="password"
                      name="oldPassword"
                      value={inputs.oldPssword}
                      onChange={handleInputChange}
                      info=""
                      lawPortal
                    />
                    <div style={{marginTop: '10px', marginBottom: '10px'}}>
                     <TextField
                      className="lw-portal-text-input"
                      title="New Password"
                      placeholder=""
                      type="password"
                      name="newPassword"
                      value={inputs.newPassword}
                      onChange={handleInputChange}
                      info=""
                      lawPortal
                    />
                    </div>
                    <div style={{ marginBottom: '10px'}}>
                     <TextField
                      className="lw-portal-text-input"
                      title="Confirm Password"
                      placeholder=""
                      type="password"
                      name="newPassword2"
                      value={inputs.newPassword2}
                      onChange={handleInputChange}
                      info=""
                      lawPortal
                    />
                    </div>
                 </div>
                <button className="acc-info-email-txt-edit" type="submit">Update</button>
                </div>
                
                )
            }
          </div>
        </form>

    
    </div>
  );
};

export default withAlert()(PasswordReset);
