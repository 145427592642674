import React from 'react';
import './location.scss';
import BackBtn from './BackBtn';
import { Info } from 'react-feather';
import Tooltip from 'react-tooltip-lite';
import microtip from 'microtip/microtip.css'
import { motion } from 'framer-motion'

const lawStatuses = [
  { id: 1, value: 'Lawyer' },
  { id: 2, value: 'Paralegal' },
];

let status = '';

const Status = (props) => {
  if (!props.location.state) {
    props.history.push({ pathname: `/search_by_location` })
  } else {
    var { city, prevPath } = props.location.state;
  }

  const onSubmitFields = () => {
    if (status.length === '') {
      props.alert.error("Select a status!");
    } else {
      props.history.push({ pathname: `/search_by_${status}`, state: { city: city, status: status, prevPath: [...prevPath, props.location.pathname] } });
    }
  };


  const btnClick = (event) => {
    if (event) event.preventDefault();
    status = event.currentTarget.value;
    onSubmitFields();
  };




  return (
    <div>
      <div className="search-header"><a href="https://counselconnect.ca/"><img className="lawyer-search-logo" src={require('./images/cc-search-logo.svg')} alt="counsel-connect-log" /></a></div>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} style={{ backgroundColor: "#f6f0f0" }} >

        <BackBtn  importantProps={{ prevPath: prevPath, city }} history={props.history} />
        
        <div className="Location" style={{ height: '35rem' }}>
          {/* <UserCheck size={70} color="#d0021b" style={{ marginBottom: "20px" }} /> */}
         
          <img className="location-search-header-icon" src={require('./images/lawyer.svg')} alt="" />

          <h1 className="location-search-title">Would you like to see a Lawyer or Paralegal?</h1>
          <p className="location-search-subtitle">Select the option that works best for you.</p>
          <div className="location-search-button-container">
            {lawStatuses.map((status, index) => (
              <div key={index} >
                <button  className="status-search-lawyer-button" value={status.value} onClick={btnClick}>{status.value}
                  <span style={{ marginLeft: "10px" }}>
                  </span>
                </button>

              </div>
            ))}
          </div>
          <div className="location-page-num">2 of 5</div>
        </div>
      </motion.div>
    </div >

  );
};

export default Status;
