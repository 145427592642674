import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { loginUser } from '../../actions/authActions';
import TextField from '../reusable/text_field/TextField';
import logo from './images/logo.svg';
import http from '../../utils/http';
import environment from '../../utils/environment';
import { withAlert } from "react-alert";
import './style/login.scss';




class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { auth, history } = this.props;
    if (auth.isAuthenticated) {
      history.push('/');
    }
  }

  componentWillReceiveProps(nextProps) {
    const { history } = this.props;
    if (nextProps.auth.isAuthenticated) {
      history.push('/');
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }
  

   async onSubmit(e) {
    e.preventDefault();
    const { email, password } = this.state;
    const userData = { email, password };

    if (!userData.email || userData.email.length === 0 || !userData.password || userData.password.length === 0) {
      this.props.alert.error("Missing Inputs!");
      return console.error('missing message');
    }

    try {
      await http.post(`${environment.resolveApi().rest}/user/login`, userData);
      window.location.reload();
    } catch (err) {
      this.props.alert.error("Email or Password does not exist!");
    }

  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors, email, password } = this.state;

    // TODO: Pull errors.login error up since we don't need it repeated
    return (
      <div className="Login">
        <img className="side-navbar-home-icon" src={logo} alt="" />

        <h1 className="login-title">Log in to CounselConnect</h1>
        <p className="login-subtitle">Sole Practitioner Login</p>

        {/* <p className="login-subtitle">Please log into your CounselConnect account</p> */}

        <form onSubmit={this.onSubmit} className="login-form-container">

          <TextField
            className="login-portal-input"
            title="Email"
            placeholder=""
            name="email"
            type="email"
            value={email}
            onChange={this.onChange}
            error={errors.login}
          />
          <div className="password-input-container">
            <TextField
              className="login-portal-input"
              title="Password"
              placeholder=""
              name="password"
              type="password"
              value={password}
              onChange={this.onChange}
              error={errors.login}
            />
            <Link to="/reset-password" className="forgot-password-link">Forgot Password?</Link>
          </div>


          <button type="submit" className="login-button">LOGIN</button>
        </form>

        <div className="login-bottom-box">
          <span className="login-bottom-box-txt">Don't have an account?</span>
          <Link to="/signup/request" className="login-bottom-box-link">Sign Up</Link>
        </div>

        <p className="copyright text-muted">Copyright &copy; 2020 by CounselConnect</p>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(withAlert()(Login));
