import React from 'react';
import { Link } from 'react-router-dom';

import configs from './configs';

import './SubNavbar.scss';

const SubNavbar = (props) => {
  const { title, config: configName } = props;

  const config = configs[configName];

  const elements = config.map((c) => {
    const { to, icon, title, subtitle } = c;

    return (
      <Link key={to} to={to} className="side-navbar-link2">
        <div className="sub-navbar-link">
          <div className="sub-navbar-icon-container">
            <img className="sub-navbar-icon" src={icon} alt="icon" />
          </div>
          <div className="sub-navbar-text-container">
            <span className="sub-navbar-text-title">{title}</span>
            <p className="sub-navbar-text-subtitle">{subtitle}</p>
          </div>
        </div>
      </Link>
    );
  });

  return (
    <div className="SubNavbar">
      <div className="sub-navbar-container">
        <span className="sub-navbar-title">{title}</span>
      </div>

      {elements}
    </div>
  );
};

export default SubNavbar;
